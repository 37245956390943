import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ApiOrderDetail, ApiOrderList, ApiPaymentLink, StartOrderPayment} from "@models/storefront";
import {QueryKeys} from "../enums/QueryKeys";
import orderApi from "../utils/OrderApi";
import purchaseApi from "../utils/PurchaseApi";
import {useCallback} from "react";
import {useParams} from "react-router-dom";


export function useOrderList({offset = 0, limit = 12}: { offset?: number, limit?: number } = {}) {
    return useQuery<ApiOrderList>(
        [QueryKeys.ORDERS_LIST, offset, limit],
        () => orderApi.getOrders({
                limit,
                offset
            }
        ),
        {keepPreviousData: true}
    )
}

export function useOrderDetail(id: string) {
    return useQuery<ApiOrderDetail>(
        [QueryKeys.ORDER_DETAIL, id],
        () => orderApi.getOrderDetail(id),
    )
}

export function usePayOrder() {
    const queryClient = useQueryClient();
    return useMutation<ApiPaymentLink, Error, StartOrderPayment>(orderApi.payOrder, {
        onSuccess: (result: ApiPaymentLink) => {
            window.location.href = result.payment.url;
        },
        onError: (error) => {
        },
        onSettled: (data, error, variables) => {
            // TODO: Add invalidate ProductDetail
            queryClient.invalidateQueries([QueryKeys.ORDER_DETAIL, variables.orderId]);
        },
    });
}

export function useMollieRedirect() {
    const queryClient = useQueryClient();

    return useMutation(orderApi.mollieRedirect, {
        onSuccess: () => {
        },
        onError: (error) => {
        },
        onSettled: (data, error, variables) => {
            // TODO: Add invalidate ProductDetail
            queryClient.invalidateQueries([QueryKeys.ORDER_DETAIL, variables.orderId]);
        },
    });
}