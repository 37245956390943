import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {ApiCartDetail, CartLineItemDetail, ShipmentMethod} from "@models/storefront";
import purchaseApi from "../utils/PurchaseApi";
import {useCallback, useMemo} from "react";
import {QueryKeys} from "../enums/QueryKeys";
import {productVariantInStock, trackProductVariantStock} from "../lib/product";

export default function useCart() {
    const queryClient = useQueryClient();
    const {isLoading, data} = useQuery<ApiCartDetail | null>(
        [QueryKeys.MY_CART],
        purchaseApi.getCart
    )

    const cart = useMemo(() => data && data.cart || null, [data]);

    const totalItemsCount = useMemo(() => {
        if (!data || !data.cart) return 0;
        return data.cart.lineItems.map((lineItem: CartLineItemDetail) => {
            return lineItem.amount;
        }).reduce((a: number, b: number) => a + b, 0);
    }, [isLoading, data])

    const lineItems = useMemo(() => {
        if (!data || !data.cart) return [];

        return data.cart.lineItems;
    }, [isLoading, data])

    const subTotal = useMemo(() => {
        return lineItems.map((lineItem: CartLineItemDetail) => {
            return lineItem.amount * lineItem.variant.price!;
        }).reduce((a: number, b: number) => a + b, 0);

    }, [lineItems])

    const tax = useMemo(() => {
        return lineItems.map((lineItem: CartLineItemDetail) => {
            return (lineItem.amount * lineItem.variant.price!)
                / ((lineItem.variant.vatPercentage! + 100))
                * lineItem.variant.vatPercentage!;
        }).reduce((a: number, b: number) => a + b, 0);
    }, [lineItems])

    const shipping = useCallback((shippingMethod?: ShipmentMethod) => {
        return shippingMethod?.price || -1;
    }, [])

    const total = useCallback((shippingMethod?: ShipmentMethod) => {
        return shippingMethod ? subTotal + shippingMethod.price! : subTotal; // Shipping
    }, [subTotal])

    const {mutate: updateProductVariantInCart} = useMutation(purchaseApi.updateProductVariantInCart, {
        onSuccess: (result: any) => {
        },
        onError: (error) => {
        },
        onSettled: () => {
            // TODO: Add invalidate ProductDetail
            queryClient.invalidateQueries([QueryKeys.MY_CART]);
        },
    });

    const removeProductVariantFromCart = useCallback((variantSku: string) => {
        updateProductVariantInCart({
            variantSku,
            amount: 0,
            isNewAmount: true
        })
    }, [])

    const canCheckout = useMemo(() => {
        let check = true;
        for(let item of lineItems) {
            if(!productVariantInStock(trackProductVariantStock(item.product, item.variant), item.variant)) {
                check = false;
                break;
            }
        }
        return check;
    }, [lineItems])

    return {
        isLoading,
        cart,
        jwt: data && data.jwt || null,
        totalItemsCount,
        updateProductVariantInCart,
        removeProductVariantFromCart,
        canCheckout,
        lineItems,
        subTotal,
        tax,
        shipping,
        total
    };
}