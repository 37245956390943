import {useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import ReactGA from "react-ga4";

export function useGA() {
    const location = useLocation();
    const params = useParams<{ lang: string }>();
    useEffect(() => {
        if (location.pathname.includes('/order')) return;
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname.replace(`/${params.lang}`, ''),
        });
    }, [location])

}