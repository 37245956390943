import {useTitle} from "react-use";
import useConfig from "../hooks/useConfig";
import {RouteTitles} from "../enums/RouteTitles";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";

export default function ShippingReturns() {
    const config = useConfig()
    useTitle(`${config.siteName} | ${RouteTitles.SHIPPINGRETURNS}`)
    const currencyFormatter = useCurrencyFormatter();

    return (
        <>
            <div>
                <div className="card w-full h-full  text-neutral-content">
                    <div className="card-body gap-0 prose text-neutral-content">
                        <h1 className="prose-2xl text-neutral-content">Informatie rondom garantie, levertijd &amp; retour</h1>

                        <h2 className={"prose-xl text-neutral-content"}>Garantie</h2>

                        <p>
                            Wij geven om onze producten en doen logischerwijze ons best om deze in topconditie aan u te leveren. <br/>
                            Toch komt het wel eens voor dat er een bestelling kapot gaat tijdens transport of dat er iets anders gebeurt waardoor u aanspraak kunt maken op garantie.<br/>
                            Op grond van het Europese recht zijn wij aansprakelijk voor elk gebrek van overeenstemming dat binnen een termijn van twee jaar na de levering van de goederen aan het licht komt.<br/>
                            U kunt aanvullende rechten hebben op grond van het Nederlandse recht.<br/>
                            Wanneer u een gebrek aan het product ontdekt of zou horen te ontdekken dient u binnen een redelijke termijn contact op te nemen met ons.<br/>
                            Een redelijke termijn is in ieder geval twee maanden. Onder omstandigheden kan een termijn van langer dan twee maanden ook acceptabel zijn.<br/>
                            Indien u tijdig contact met ons opneemt zullen wij zorgen voor een kosteloze reparatie of voor vervanging.
                        </p>

                        <h2 className={"prose-xl text-neutral-content"}>Levertijd</h2>

                        <p>
                            Wij doen zorgvuldig ons best de bestelling zo spoedig mogelijk bij u af te leveren.<br/>
                            Bestellingen die op werkdagen vóór 18.00 worden gedaan, proberen wij uiterlijk de volgende dag te verzenden.<br/>
                            Niet altijd lukt het ons echter om dit na te komen. Soms zijn producten niet voorradig en dan kan de levering van uw bestelling iets langer duren.<br/>
                            Op de productpagina staat een indicatie van de levertijd. Mochten wij om wat voor reden dan ook deze levertijd niet halen, dan brengen wij u hier natuurlijk zo spoedig mogelijk van op de hoogte.<br/>
                            <br/>
                            De vermelde prijzen zijn exclusief verzendkosten. De verzendkosten bedragen:<br/>

                            <ul>
                                <li>
                                    {currencyFormatter(2.95)} voor bestellingen die door de brievenbus passen
                                </li>
                                <li>
                                    {currencyFormatter(5.95)} voor bestellingen onder de {currencyFormatter(75)}
                                </li>
                                <li>
                                    Gratis voor bestellingen vanaf {currencyFormatter(75)}
                                </li>
                            </ul>

                            Levering verloopt via de postbode of pakketbezorger van PostNL of DHL.<br/>
                            Over het algemeen zal de aflevering de eerstvolgende werkdag na verzenden tussen 9:00 en 18:00 plaatsvinden.<br/>
                            Helaas kunnen wij het moment van aflevering niet garanderen.
                        </p>

                        <h2 className={"prose-xl text-neutral-content"}>Retourneren</h2>
                        <p>
                            U heeft het recht uw bestelling tot 14 dagen na ontvangst zonder opgave van reden te
                            annuleren. U heeft na annulering nogmaals 14 dagen om uw product retour te sturen. U krijgt
                            dan het volledige orderbedrag inclusief verzendkosten gecrediteerd. Enkel de kosten voor
                            retour van u thuis naar de webwinkel zijn voor eigen rekening.<br/> Indien u gebruik maakt van uw
                            herroepingsrecht, zal het product met alle geleverde toebehoren en - indien redelijkerwijze
                            mogelijk - in de originele staat en verpakking aan de ondernemer geretourneerd worden.<br/> Om
                            gebruik te maken van dit recht kunt u contact met ons opnemen via <a href={"mailto:info@sidequestshop.nl?subject=Retour%20aanmelden"}>info@sidequestshop.nl</a>.<br/> Wij
                            zullen vervolgens het verschuldigde orderbedrag binnen 14 dagen na aanmelding van uw retour
                            terugstorten mits het product reeds in goede orde retour ontvangen is.
                        </p>


                        <h3 className={"prose-lg text-neutral-content"}>Uitzonderingen retourneren</h3>

                        <p>
                            Het herroepingsrecht geld niet voor/wanneer:
                            <ul>
                                <li>Bij verzegelde producten. Wanneer de verzegeling verbroken is zijn bij deze producten niet retourneerbaar;</li>
                                <li>Door SideQuestShop op maat gemaakte producten die overeenkomstig zijn met de specificaties van de consument;</li>
                                <li>Die duidelijk persoonlijk van aard zijn;</li>
                                <li>Die door hun aard niet kunnen worden teruggezonden;</li>
                                <li>Die snel kunnen bederven of verouderen;</li>
                                <li>Waarvan de prijs gebonden is aan schommelingen op de financiële markt waarop de ondernemer geen invloed heeft;</li>

                            </ul>

                        </p>


                        <h2 id={"identiteit-ondernemer"} className={"prose-xl text-neutral-content"}>Identiteit ondernemer</h2>

                        <address>
                            SideQuestShop<br/>
                            Het Haam 4<br/>

                            6846KW Arnhem<br/>
                            Nederland
                        </address>

                        <p>
                            {/*<b>T</b><br/>*/}
                            <b>E</b> info@sidequestshop.nl<br/>
                            <b>KVK</b> 65366247<br/>
                            <b>BTW nummer</b> NL002301617B64
                        </p>

                        {/*<div className="card-actions justify-end">*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )

}
