import {useQuery} from "@tanstack/react-query";
import {ApiProductDetail, ApiProductList, Product, ProductVariant} from "@models/storefront";
import {QueryKeys} from "../enums/QueryKeys";
import productApi from "../utils/ProductApi";
import {useMemo} from "react";
import {productVariantInStock, trackProductVariantStock} from "../lib/product";


export function useProductList({offset = 0, limit = 12}: { offset?: number, limit?: number } = {}) {
    return useQuery<ApiProductList>(
        [QueryKeys.PRODUCTS_LIST, offset, limit],
        () => productApi.getProducts({
                limit,
                offset
            }
        ),
        {keepPreviousData: true}
    )
}

export function useProductDetail(variantUrl: string) {
    return useQuery<ApiProductDetail>(
        [QueryKeys.PRODUCT_DETAIL, variantUrl],
        () => productApi.getProductDetail(variantUrl),
    )
}

export function useVariantInStock(product?: Product, variant?: ProductVariant) {
    const trackStock = useMemo(() => trackProductVariantStock(product, variant), [product])

    const inStock = useMemo(() => productVariantInStock(trackStock, variant), [trackStock, product, variant])

    return {trackStock, inStock}
}