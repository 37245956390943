import useCart from "../hooks/useCart";
import {
    CartLineItemDetail,
    Courier,
    CreateOrder,
    MollieMethodIssuer,
    MollieMethodWithIssuer,
    ShipmentMethod
} from "@models/storefront";
import {ProductPicture} from "../components/picture/Picture";
import {useTranslations} from "../hooks/useTranslations";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";
import useCheckout from "../hooks/useCheckout";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {FaCheckCircle} from 'react-icons/fa';
import {useCallback, useEffect, useMemo} from "react";
import {useCourierList} from "../hooks/useCourier";
import {Controller, FormProvider, useForm, useFormContext} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type AddressKeys = 'shippingAddress' | 'invoiceAddress';

function AddressInput({addressKey,}: { addressKey: AddressKeys, }) {
    const {register, watch} = useFormContext();
    const {t} = useTranslation();

    const watchCountry = watch(`${addressKey}.country`);
    const invoiceSameAsShipping = watch('invoiceSameAsShipping', true);

    const hide = useMemo(() => {
        if (addressKey === 'shippingAddress') return false;
        return invoiceSameAsShipping;
    }, [invoiceSameAsShipping, addressKey])

    const required = useMemo(() => {
        if (addressKey === 'shippingAddress') return true;
        return !hide
    }, [hide, addressKey])

    return (
        <>
            <div
                className={classNames('transition-max-height overflow-hidden duration-1000', hide ? 'max-h-0' : 'max-h-[50rem]')}>
                <div className={"mt-6 grid grid-cols-1 gap-y-6 gap-x-4"}>
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.firstname')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.firstName`, {
                                            required,
                                        })
                                    }
                                    type="text"
                                    name={`${addressKey}.firstName`}
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.middlename')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.middleName`)
                                    }
                                    type="text"
                                    name={`${addressKey}.middleName`}
                                    autoComplete="additional-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.lastname')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.lastName`, {
                                            required
                                        })
                                    }
                                    name={`${addressKey}.lastName`}
                                    type="text"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.addressLine1')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.streetLine1`, {
                                            required
                                        })
                                    }
                                    type="text"
                                    name={`${addressKey}.streetLine1`}
                                    autoComplete="shipping address-line-1"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.addressLine2')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.streetLine2`)
                                    }
                                    type="text"
                                    name={`${addressKey}.streetLine2`}
                                    autoComplete="address-line2"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="postal-code"
                                   className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.postalCode')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.postalCode`, {
                                            required
                                        })
                                    }
                                    type="text"
                                    name={`${addressKey}.postalCode`}
                                    autoComplete="postal-code"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.stateProvince')}
                            </label>
                            <div className="mt-1">
                                {
                                    watchCountry === 'Netherlands' ? (
                                        <select
                                            {
                                                ...register(`${addressKey}.province`, {
                                                    required
                                                })
                                            }
                                            name={`${addressKey}.province`}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                        >

                                            {
                                                [
                                                    'Drenthe', 'Flevoland', 'Friesland', 'Gelderland', 'Groningen', 'Limburg', 'Noord-Brabant', 'Noord-Holland', 'Overijssel', 'Utrecht', 'Zeeland', 'Zuid-Holland'
                                                ].map((province: string) => (
                                                    <option key={province} value={province}>{province}</option>
                                                ))
                                            }
                                        </select>
                                    ) : (
                                        <input
                                            {
                                                ...register(`${addressKey}.province`, {
                                                    required
                                                })
                                            }
                                            type="text"
                                            name={`${addressKey}.province`}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                        />
                                    )
                                }


                            </div>
                        </div>

                        <div>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.city')}
                            </label>
                            <div className="mt-1">
                                <input
                                    {
                                        ...register(`${addressKey}.city`, {
                                            required
                                        })
                                    }
                                    type="text"
                                    name={`${addressKey}.city`}
                                    autoComplete="address-level2"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor={`${addressKey}.country`}
                                   className="block text-sm font-medium text-gray-700">
                                {t('addressInfo.country')}
                            </label>
                            <div className="mt-1">
                                <select
                                    value={"Netherlands"}
                                    name={`${addressKey}.country`}
                                    disabled
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                >
                                    <option value={"Netherlands"}>Netherlands</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default function CheckoutScreen() {
    const {cart, lineItems, subTotal, tax, shipping, total} = useCart();
    const {data: couriers} = useCourierList()
    const {tApi, href, t} = useTranslations();
    const currencyFormatter = useCurrencyFormatter()

    const navigate = useNavigate();

    useEffect(() => {
        if (lineItems.length === 0) {
            navigate('/cart')
        }
    }, [navigate, lineItems])

    const {paymentMethods, pickShipmentMethod, pickShipmentCourier, mutatePlaceOrder} = useCheckout();
    const methods = useForm<CreateOrder>({
        defaultValues: {
            email: '',
            paymentMethod: 'ideal',
            issuer: '',
            courier: '',
            shippingMethod: '',
            invoiceSameAsShipping: true,
            shippingAddress: {
                firstName: '',
                middleName: '',
                lastName: '',
                streetLine1: '',
                streetLine2: '',
                postalCode: '',
                city: '',
                province: '',
                country: 'Netherlands',
            },
            invoiceAddress: {
                firstName: '',
                middleName: '',
                lastName: '',
                streetLine1: '',
                streetLine2: '',
                postalCode: '',
                city: '',
                province: '',
                country: 'Netherlands',
            }
        }
    });

    const {register, handleSubmit, control, setValue, watch} = methods;

    const courierId = watch('courier')
    const shippingMethodId = watch('shippingMethod')

    const courier = useMemo(() => {
        return couriers?.courier.find((courier: Courier) => courier.id === courierId);
    }, [courierId, couriers])

    const shippingMethod = useMemo(() => {
        if (!courier) return;
        return courier.methods.find((method: ShipmentMethod) => method.shipmentMethodId === shippingMethodId)
    }, [shippingMethodId, courier])

    const onSubmit = useCallback((data: any) => {
        mutatePlaceOrder(data);
    }, [])

    return (
        <>
            {/* Background color split screen for large screens */}
            {/*<div className="fixed top-0 left-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />*/}
            {/*<div className="fixed top-0 right-0 hidden h-full w-1/2 bg-primary-900 lg:block" aria-hidden="true" />*/}

            <div className="grid grid-cols-1 gap-x-8 lg:grid-cols-2">
                <h1 className="sr-only">{t('checkout.title')}</h1>

                <section
                    aria-labelledby="summary-heading"
                    className="bg-primary-900 text-primary-300 lg:col-start-2 lg:row-start-1 lg:w-full lg:max-w-lg lg:pb-24"
                >
                    <div className="p-8">
                        <h2 id="summary-heading" className="sr-only">
                            {t('orderSummary')}
                        </h2>

                        <dl>
                            <dt className="text-sm font-medium">{t('amountDue')}</dt>
                            <dd className="mt-1 text-3xl font-bold tracking-tight text-white">{currencyFormatter(total(shippingMethod))}</dd>
                        </dl>

                        <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                            {lineItems.map((lineItem: CartLineItemDetail) => (
                                <li key={lineItem.variantSku} className="flex items-start space-x-4 py-6">
                                    <ProductPicture
                                        path={`${lineItem.variant.images[0]}-sm`}
                                        alt={tApi(lineItem.variant.name)}
                                        className="h-20 w-20 flex-none rounded-md"
                                    />
                                    <div className="flex-auto space-y-1">
                                        <h3 className="text-white">{tApi(lineItem.product.name)}</h3>
                                        <p>{tApi(lineItem.variant.name)}</p>
                                        <p className="flex-none  text-sm text-primary-300">{lineItem.amount} x {currencyFormatter(lineItem.variant.price!)}</p>
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <p className="flex-none  font-medium text-white">{currencyFormatter(lineItem.amount * lineItem.variant.price!)}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
                            <div className="flex items-center justify-between">
                                <dt>{t('subtotal')}</dt>
                                <dd>{currencyFormatter(subTotal)}</dd>
                            </div>

                            <div className="flex items-center justify-between">
                                <dt>{t('shippingCost')}</dt>
                                <dd>{shipping(shippingMethod) === -1 ? t('selectShippingMethod') : currencyFormatter(shipping(shippingMethod))}</dd>
                            </div>

                            <div className="flex items-center justify-between">
                                <dt>{t('tax.plural')}</dt>
                                <dd>{currencyFormatter(tax)}</dd>
                            </div>

                            <div
                                className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                                <dt className="text-base">{t('total')}</dt>
                                <dd className="text-base">{shipping(shippingMethod) === -1 ? t('selectShippingMethod') : currencyFormatter(total(shippingMethod))}</dd>
                            </div>
                        </dl>
                    </div>
                </section>

                <section
                    aria-labelledby="payment-and-shipping-heading"
                    className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24"
                >
                    <h2 id="payment-and-shipping-heading" className="sr-only">
                        {t('checkout.paymentAndShippingDetails')}
                    </h2>

                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                                <div>
                                    <h3 id="contact-info-heading" className="text-lg font-medium text-gray-900">
                                        {t('checkout.contactInfo')}
                                    </h3>

                                    <div className="mt-6">
                                        <label htmlFor="email-address"
                                               className="block text-sm font-medium text-gray-700">
                                            {t('emailAddress')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                {
                                                    ...register('email', {
                                                        required: true,
                                                    })
                                                }

                                                type="email"
                                                id="email-address"
                                                name="email"
                                                autoComplete="email"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-10">
                                    <h3 className="text-lg font-medium text-gray-900">{t('order.shippingAddress')}</h3>

                                    <AddressInput addressKey={'shippingAddress'}/>
                                </div>

                                <div className="mt-10">
                                    <h3 className="text-lg font-medium text-gray-900">{t('checkout.billingInformation')}</h3>

                                    <div className="mt-6 flex items-center">
                                        <input
                                            {...register('invoiceSameAsShipping')}
                                            id="invoiceSameAsShipping"
                                            name="invoiceSameAsShipping"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                                        />
                                        <div className="ml-2">
                                            <label htmlFor="invoiceSameAsShipping"
                                                   className="text-sm font-medium text-gray-900">
                                                {t('checkout.sameAsShipping')}
                                            </label>
                                        </div>
                                    </div>

                                    <AddressInput addressKey={'invoiceAddress'}/>
                                </div>

                                <div className="mt-10 border-t border-gray-200 pt-10">
                                    <Controller
                                        name="courier"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field, fieldState}) => (
                                            <RadioGroup {...field}>
                                                <RadioGroup.Label className="text-lg font-medium text-gray-900">
                                                    {t('checkout.shippingMethods')}
                                                </RadioGroup.Label>

                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    {(couriers?.courier ?? []).map((courier: Courier) => (
                                                        <RadioGroup.Option
                                                            key={courier.id}
                                                            value={courier.id}
                                                            className={({checked, active}) =>
                                                                classNames(
                                                                    checked ? 'border-transparent' : 'border-gray-300',
                                                                    active ? 'ring-2 ring-primary-500' : '',
                                                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                                                                    fieldState.error && 'ring-2 ring-red-500',
                                                                )
                                                            }
                                                        >
                                                            {({checked, active}) => (
                                                                <>
                                                      <span className="flex flex-col flex-1">
                                                        <span className="flex flex-col">
                                                            <span className="flex flex-row justify-between">
                                                                <span className="flex flex-row gap-x-2">

                                                              <RadioGroup.Label as="span"
                                                                                className="block font-medium text-gray-900 ">
                                                                  <img src={courier.logo} alt={tApi(courier.name)}
                                                                       className={"w-8 h-8"}/>
                                                              </RadioGroup.Label>
                                                              <RadioGroup.Description
                                                                  as="span"
                                                                  className="mt-1 md:mt-0 flex items-center "
                                                              >
                                                                {tApi(courier.name)}
                                                                  {/*{paymentMethod.turnaround}*/}
                                                              </RadioGroup.Description>
                                                                </span>
                                                                <span>
                                                                     {checked ? <FaCheckCircle
                                                                         className="h-5 w-5 text-primary-600"
                                                                         aria-hidden="true"/> : null}
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'border' : 'border-2',
                                                                            checked ? 'border-primary-500' : 'border-transparent',
                                                                            'pointer-events-none absolute -inset-px rounded-lg'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                            </span>
                                                            {courier.methods && courier.methods.length > 0 && (
                                                                <RadioGroup.Description as="span"
                                                                                        className="mt-6 text-sm font-medium text-gray-900">
                                                                    <select
                                                                        {...register('shippingMethod', {required: true})}
                                                                        className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
                                                                    >
                                                                        <option value="" disabled>{t('select.placeholder')}</option>
                                                                        {
                                                                            courier.methods.map((shipmentMethod: ShipmentMethod) => (
                                                                                    <option
                                                                                        key={shipmentMethod.shipmentMethodId}
                                                                                        value={shipmentMethod.shipmentMethodId}
                                                                                    >
                                                                                        {tApi(shipmentMethod.name)} {currencyFormatter(shipmentMethod.price!)}
                                                                                    </option>
                                                                                )
                                                                            )
                                                                        }
                                                                    </select>
                                                                </RadioGroup.Description>
                                                            )}
                                                        </span>
                                                      </span>
                                                                </>
                                                            )}
                                                        </RadioGroup.Option>
                                                    ))}
                                                </div>
                                            </RadioGroup>

                                        )}/>
                                </div>
                                <div className="mt-10 border-t border-gray-200 pt-10">
                                    <Controller
                                        name="paymentMethod"
                                        control={control}
                                        render={({field}) => (

                                            <RadioGroup
                                                {...field}
                                                // onChange={(method: string) => setPaymentMethod('method', method)}
                                            >
                                                <RadioGroup.Label className="text-lg font-medium text-gray-900">
                                                    Payment Methods
                                                </RadioGroup.Label>

                                                <div
                                                    className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                                    {paymentMethods.map((paymentMethod: MollieMethodWithIssuer) => (
                                                        <RadioGroup.Option
                                                            key={paymentMethod.id}
                                                            value={paymentMethod.id}
                                                            className={({checked, active}) =>
                                                                classNames(
                                                                    checked ? 'border-transparent' : 'border-gray-300',
                                                                    active ? 'ring-2 ring-primary-500' : '',
                                                                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                                                                )
                                                            }
                                                        >
                                                            {({checked, active}) => (
                                                                <>
                                                      <span className="flex flex-1">
                                                        <span className="flex flex-col">
                                                            <span className="flex flex-row gap-x-2">

                                                          <RadioGroup.Label as="span"
                                                                            className="block font-medium text-gray-900">
                                                              <img src={paymentMethod.image.svg}
                                                                   alt={paymentMethod.description}/>
                                                          </RadioGroup.Label>
                                                          <RadioGroup.Description
                                                              as="span"
                                                              className="mt-1 md:mt-0 flex items-center "
                                                          >
                                                            {paymentMethod.description}
                                                              {/*{paymentMethod.turnaround}*/}
                                                          </RadioGroup.Description>
                                                            </span>
                                                            {paymentMethod.issuers && paymentMethod.issuers.length > 0 && (
                                                                <RadioGroup.Description as="span"
                                                                                        className="mt-6 text-sm font-medium text-gray-900">
                                                                    <select
                                                                        {
                                                                            ...register('issuer')
                                                                        }
                                                                        placeholder={'Bank'}
                                                                        name={'issuer'}
                                                                        className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
                                                                    >
                                                                        {
                                                                            paymentMethod.issuers.map((issuer: MollieMethodIssuer) => (
                                                                                    <option
                                                                                        key={issuer.id}
                                                                                        value={issuer.id}
                                                                                    >
                                                                                        {issuer.name}
                                                                                    </option>
                                                                                )
                                                                            )
                                                                        }
                                                                    </select>
                                                                </RadioGroup.Description>
                                                            )}
                                                        </span>
                                                      </span>
                                                                    {checked ?
                                                                        <FaCheckCircle
                                                                            className="h-5 w-5 text-primary-600"
                                                                            aria-hidden="true"/> : null}
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'border' : 'border-2',
                                                                            checked ? 'border-primary-500' : 'border-transparent',
                                                                            'pointer-events-none absolute -inset-px rounded-lg'
                                                                        )}
                                                                        aria-hidden="true"
                                                                    />
                                                                </>
                                                            )}
                                                        </RadioGroup.Option>
                                                    ))}
                                                </div>
                                            </RadioGroup>
                                        )}
                                    />
                                </div>


                                <div className="mt-10 flex justify-end border-t border-gray-200 pt-6">
                                    <button
                                        type="submit"
                                        className="rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        {t('payNow')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </section>
            </div>
        </>
    )
}