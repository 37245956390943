
export enum QueryKeys {
    MY_CART = 'my-cart',
    PAYMENT_METHOD_LIST = 'payment-method-list',
    PRODUCTS_LIST = 'products-list',
    COURIERS_LIST = 'couriers-list',
    PRODUCT_DETAIL = 'product-detail',

    ORDERS_LIST = 'orders-list',
    ORDER_DETAIL = 'order-detail',



    TRANSLATIONS_LIST = 'translations-detail',
}