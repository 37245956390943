import config from "./config";
import localforage from 'localforage';
import {LocalStorageKeys} from "../enums/LocalStorage";

export type Method = 'GET' | 'POST' | 'PATCH' | 'DELETE'

export default class Api {
    apiUrl: string;

    constructor() {
        this.apiUrl = config.apiUrl;
    }

    getJwt = async () => {
        try {
            return localStorage.getItem(LocalStorageKeys.JWT_KEY);
        } catch (e) {
            return null;
        }
    }

    storeJwt = async (jwtValue: string) => {
        try {
            await localforage.setItem(LocalStorageKeys.JWT_KEY, jwtValue);
        } catch (e) {
            console.log('Something went wrong storing jwt value')
            console.log(e);
            return;
        }
    }

    genFetchOptions = async ({
                                 method = 'GET',
                                 auth = false,
                                 body = {}
                             }: { method?: Method, auth?: boolean, body?: Record<string, any> } = {}) => {
        let headers: Headers = new Headers();
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');

        const opts: RequestInit = {
            method,
            credentials: 'include',
            headers,
        }

        const jwt = await this.getJwt();
        if (jwt) {
            headers.set('Authorization', `Bearer: ${jwt}`)
            // else throw new Error('Requires auth, user not logged in');
        }

        if (method === 'POST' || method === 'PATCH') {
            opts.body = JSON.stringify(body)
        }

        return opts;
    }

    genUrl = (path: string = '', query?: Record<string, string | number>) => {
        const url = new URL(`${this.apiUrl}/${path}`.replaceAll(/\/{2,}/g, '/'));

        if (query) {
            for (const key in query) {
                url.searchParams.set(key, `${query[key]}`)
            }
        }

        return url;
    }
}