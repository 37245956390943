import Api from "./Api";
import {ApiPaymentLink, StartOrderPayment} from "@models/storefront";

export class OrderApi extends Api {

    constructor() {
        super();
    }

    async getOrders({limit = 12, offset = 0} : { limit?:number, offset?: number }) {
        const options = await this.genFetchOptions();
        const url = this.genUrl('/order', {limit, offset});

        const res = await fetch(url, options);
        return res.json();
    }

    async getOrderDetail(orderId: string) {
        const options = await this.genFetchOptions()
        const url = this.genUrl(`/order/${orderId}`);

        const res = await fetch(url, options)
        return res.json();
    }

    payOrder = async ({orderId, method, issuer, lang}: StartOrderPayment): Promise<ApiPaymentLink> => {
        const options = await this.genFetchOptions({method: 'POST'});
        let link = `/order/payment/initiate`;
        link += `?orderId=${orderId}&lang=${lang}`;
        if(method) link += `&method=${method}`;
        if(issuer) link += `&issuer=${issuer}`;

        const url = this.genUrl(link);

        const res = await fetch(url, options);
        return res.json();
    }

    mollieRedirect = async ({paymentId}: {orderId: string, paymentId: string}) => {
        const options = await this.genFetchOptions({method: 'POST'});
        const url = this.genUrl(`payment/mollie/redirect/${paymentId}`);

        await fetch(url, options);
    }

}

export default new OrderApi();