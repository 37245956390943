import {createContext, ReactNode, useCallback, useEffect} from 'react';
import {useLocalStorage} from "react-use";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Transition} from "@headlessui/react";
import purchaseApi from "../../utils/PurchaseApi";
import {ApiJwtCart, Cart} from "@models/storefront";
import useCart from "../../hooks/useCart";
import {LocalStorageKeys} from "../../enums/LocalStorage";

export type StoreContextType = {
    cookieAccept: number | null,
    jwt: string | null;
    cart: Cart | null;
    // languages: Translation[]
}

export const StoreContext = createContext<StoreContextType>({
    cookieAccept: null,
    jwt: null,
    cart: null,
});

export function StoreProvider({children}: { children: ReactNode }) {
    const [cookieAccept, setCookieAccept, removeCookieAccept] = useLocalStorage<StoreContextType['cookieAccept']>(LocalStorageKeys.COOKIE_ACCEPTED, null);
    const [jwt, setJwt, removeJwt] = useLocalStorage<StoreContextType['jwt']>(LocalStorageKeys.JWT_KEY, null, {raw: true});


    const {isLoading, cart, jwt: newJwt} = useCart();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!isLoading && newJwt && jwt !== newJwt) {
            setJwt(newJwt);
        }
    }, [isLoading, newJwt]);

    const {mutate} = useMutation(purchaseApi.acceptCookie, {
        onSuccess: ({cart, jwt}: ApiJwtCart) => {
            setJwt(jwt);
            queryClient.invalidateQueries(['my-cart'])
        },
        onError: (error) => {
        },
        onSettled: () => {
            // TODO: Add invalidate ProductDetail and Cart
            // queryClient.invalidateQueries([`logistics-units-${companyId}`]);
        },
    });

    const acceptCookies = useCallback(() => {
        setCookieAccept(Date.now());
        mutate();
    }, [])

    return (
        <StoreContext.Provider
            value={{
                cookieAccept: cookieAccept as StoreContextType['cookieAccept'],
                jwt: jwt as StoreContextType['jwt'],
                cart,
            }}>
            {children}

            <Transition show={!jwt || !cookieAccept}
                        enterFrom="opacity-0 scale-90"
                        enter="transition duration-200 transform ease"
                        leave="transition duration-200 transform ease"
                        leaveTo="opacity-0 scale-90"
                        className="fixed bottom-0 left-0 w-screen bg-gray-100 flex flex-col justify-center sm:py-12">
                <div className="container">
                    <div
                        className="bg-white inset-x-5 p-5 bottom-40 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between">
                        <div className="w-full">This website uses cookies to ensure you get the best experience on our
                            website.
                            <a href="#" className="text-primary-600 whitespace-nowrap  hover:underline">Learn more</a>
                        </div>
                        <div className="flex gap-4 items-center flex-shrink-0">
                            {/*<button onClick="open = false, setTimeout(() => open = true, 1500)"*/}
                            {/*        class="text-primary-600 focus:outline-none hover:underline">Decline*/}
                            {/*</button>*/}
                            <button onClick={acceptCookies}
                                    className="bg-primary-500 px-5 py-2 text-white rounded-md hover:bg-primary-700 focus:outline-none">Allow
                                Cookies
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>

        </StoreContext.Provider>
    )

}

