import {useMollieRedirect, useOrderDetail, usePayOrder} from "../hooks/useOrder";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSearchParam} from "react-use";
import {useCallback, useEffect, useMemo} from "react";
import {InputAddress, Order, OrderLineItem} from "@models/storefront";
import Picture, {DisplaysPicture, ProductPicture} from "../components/picture/Picture";
import {useTranslations} from "../hooks/useTranslations";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";
import {useTranslation} from "react-i18next";

function AddressInfo({address}: { address: InputAddress }) {
    return (
        <address className="not-italic">
            <span className="block">{address.fullName}</span>
            <span className="block">{address.streetLine1}</span>
            {
                address.streetLine2 && (
                    <span className="block">{address.streetLine2}</span>
                )
            }
            <span className="block">{address.postalCode} {address.city}</span>
            <span className="block">{address.country}</span>
        </address>
    )
}

// Copy Paste and I don't now why
export enum OrderState {
    ArrangingPayment = "ArrangingPayment",
    Paid = "Paid",
    PartiallyShipped = "PartiallyShipped",
    Shipped = "Shipped",
    PartiallyDelivered = "PartiallyDelivered",
    Delivered = "Delivered",
    PartiallyReturned = "PartiallyReturned",
    Returned = "Returned",
    PartiallyRefunded = "PartiallyRefunded",
    Refunded = "Refunded",
    Cancelled = "Cancelled",
}

function PaymentStatus({order}: { order: Order }) {
    const {t} = useTranslation()
    switch (order.state) {
        case OrderState.ArrangingPayment:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.arrangingPayment')}</h1>);
        case OrderState.Paid:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.paid')}</h1>);
        case OrderState.Cancelled:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.cancelled')}</h1>);
        case OrderState.Refunded:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.refunded')}</h1>);
        case OrderState.Shipped:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.shipped')}</h1>);
        case OrderState.Delivered:
            return (<h1 className="text-sm font-medium text-primary-600">{t('orderStatus.delivered')}</h1>);
        default:
            return <></>
    }
}

export function OrderSummary() {
    const params = useParams<{ orderId: string, lang: string }>()
    const {data, isLoading} = useOrderDetail(params.orderId!);
    const navigate = useNavigate();
    const {tApi, href, t} = useTranslations()
    const currencyFormatter = useCurrencyFormatter();
    const order = useMemo(() => data && data.order, [data]);
    const method = useSearchParam('method')
    const issuer = useSearchParam('issuer')
    const paymentId = useSearchParam('paymentId')

    const {mutate: startPayment} = usePayOrder();
    const {mutate: mollieRedirect} = useMollieRedirect();

    const payOrder = useCallback(() => {
        if (!order) return;
        startPayment({
            orderId: order.id,
            lang: params.lang || 'en',
            method: method || undefined,
            issuer: issuer || undefined,
        })
    }, [order, startPayment])

    useEffect(() => {
        if (isLoading) return;
        if (!order) return;
        if (order.state === OrderState.ArrangingPayment && method) {
            payOrder();
        }
    }, [isLoading, order, payOrder])

    useEffect(() => {
        if (paymentId && params) mollieRedirect({
            orderId: params.orderId!,
            paymentId
        })
    }, [paymentId, params])

    if (!order && isLoading) {
        return (<>
            <main className="relative lg:min-h-full">
                <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
                    <img
                        src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
                        alt="TODO"
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div>
                    <div
                        className="mx-auto max-w-2xl py-16 px-4 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
                        <div className="lg:col-start-2">
                            <h1 className="text-sm font-medium text-primary-600">Loading order...</h1>
                            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('orderSummary.thanks')}</p>
                            {/*<p className="mt-2 text-base text-gray-500">*/}
                            {/*    We appreciate your order, we’re currently processing it. So hang tight and we’ll send*/}
                            {/*    you*/}
                            {/*    confirmation*/}
                            {/*    very soon!*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
            </main>
        </>)
    }

    if (!order && !isLoading) {
        navigate('/cart');
        return (<></>)
    }

    if (!order) {
        navigate('/cart');
        return (<></>)
    }

    return (
        <main className="relative lg:min-h-full">
            <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-2/5 lg:pr-4 xl:pr-12">
                {/*<div className="absolute inset-0">*/}
                    <DisplaysPicture path={'/christmas-2022/christmas-display-2xl'} full
                                     alt={"Christmas 2022 Display"}/>
                    <div className="sticky h-full inset-0 bg-primary-900 mix-blend-soft-light"/>

                {/*</div>*/}
            </div>

            <div
                className="py-16 px-4 sm:px-6 sm:py-24 lg:grid lg:grid-cols-5 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
                <div className="lg:col-start-3 lg:col-span-3">
                    <PaymentStatus order={order}/>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t('orderSummary.thanks')}</p>
                    {/*<p className="mt-2 text-base text-gray-500">*/}
                    {/*    We appreciate your order, we’re currently processing it. So hang tight and we’ll send you*/}
                    {/*    confirmation*/}
                    {/*    very soon!*/}
                    {/*</p>*/}


                    {
                        order.state === OrderState.ArrangingPayment && (
                            <dl className="mt-16 text-sm font-medium">
                                <dt className="text-gray-900">{t('orderSummary.awaitingPayment')}</dt>
                                <dd className="mt-2 text-primary-600">
                                    <button
                                        onClick={payOrder}
                                        className="rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                    >
                                        {t('payNow')}
                                    </button>
                                </dd>
                            </dl>
                        )
                    }


                    <dl className="mt-16 text-sm font-medium">
                        <dt className="text-gray-900">{t('invoiceNo')}</dt>
                        <dd className="mt-2 text-primary-600">{order.invoiceNo}</dd>
                    </dl>
                    {
                        false && (
                            <dl className="mt-16 text-sm font-medium">
                                <dt className="text-gray-900">Tracking number</dt>
                                <dd className="mt-2 text-primary-600">51547878755545848512</dd>
                            </dl>
                        )
                    }

                    <ul
                        role="list"
                        className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
                    >
                        {order.lineItems.map((lineItem: OrderLineItem) => (
                            <li key={lineItem.variant.sku} className="flex space-x-6 py-6">
                                <ProductPicture
                                    path={`${lineItem.variant.images[0]}-sm`}
                                    alt={tApi(lineItem.variant.name)}
                                    className="h-24 w-24 flex-none  rounded-md object-cover object-center"
                                />
                                <div className="flex-auto space-y-1">
                                    <h3 className="text-gray-900">
                                        <Link
                                            to={href(`products/${tApi(lineItem.variant.url)}`, true)}>
                                            {tApi(lineItem.variant.name)}
                                        </Link>
                                    </h3>
                                    <p>
                                        {lineItem.amount} x {currencyFormatter(lineItem.variant.price!)}
                                    </p>
                                    {/*<p>{product.color}</p>*/}
                                    {/*<p>{product.size}</p>*/}
                                </div>
                                <p className="flex-none font-medium text-gray-900">{currencyFormatter(lineItem.amount * lineItem.variant.price!)}</p>
                            </li>
                        ))}
                    </ul>

                    <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                        <div className="flex justify-between">
                            <dt>{t('subtotal')}</dt>
                            <dd className="text-gray-900">{currencyFormatter(order.total)}</dd>
                        </div>

                        <div className="flex justify-between">
                            <dt>{t('shippingCost')}</dt>
                            <dd className="text-gray-900">{currencyFormatter(order.shipmentMethod!.price!)}</dd>
                        </div>

                        {
                            false && (
                                <div className="flex justify-between">
                                    <dt>Taxes</dt>
                                    <dd className="text-gray-900">$6.40</dd>
                                </div>
                            )
                        }

                        <div
                            className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                            <dt className="text-base">{t('total')}</dt>
                            <dd className="text-base">{currencyFormatter(order.total)}</dd>
                        </div>
                    </dl>

                    <dl className="mt-16 grid grid-cols-3 gap-x-4 text-sm text-gray-600">
                        <div>
                            <dt className="font-medium text-gray-900">{t('order.shippingAddress')}</dt>
                            <dd className="mt-2">
                                <AddressInfo address={order.shipmentInfo}/>
                            </dd>
                        </div>
                        <div>
                            <dt className="font-medium text-gray-900">{t('order.invoiceAddress')}</dt>
                            <dd className="mt-2">
                                {
                                    order.invoiceSameAsBilling ? (
                                        <AddressInfo address={order.shipmentInfo}/>
                                    ) : (
                                        <AddressInfo address={order.invoiceInfo!}/>
                                    )
                                }
                            </dd>
                        </div>
                        {/*<div>*/}
                        {/*    <dt className="font-medium text-gray-900">Payment Information</dt>*/}
                        {/*    <dd className="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4">*/}
                        {/*        <div className="flex-none">*/}
                        {/*            <svg*/}
                        {/*                aria-hidden="true"*/}
                        {/*                width={36}*/}
                        {/*                height={24}*/}
                        {/*                viewBox="0 0 36 24"*/}
                        {/*                xmlns="http://www.w3.org/2000/svg"*/}
                        {/*                className="h-6 w-auto"*/}
                        {/*            >*/}
                        {/*                <rect width={36} height={24} rx={4} fill="#224DBA"/>*/}
                        {/*                <path*/}
                        {/*                    d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"*/}
                        {/*                    fill="#fff"*/}
                        {/*                />*/}
                        {/*            </svg>*/}
                        {/*            <p className="sr-only">Visa</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="flex-auto">*/}
                        {/*            <p className="text-gray-900">Ending with 4242</p>*/}
                        {/*            <p>Expires 12 / 21</p>*/}
                        {/*        </div>*/}
                        {/*    </dd>*/}
                        {/*</div>*/}
                    </dl>

                    <div className="mt-16 border-t border-gray-200 py-6 text-right">
                        <Link to={href('/', true)}
                              className="text-sm font-medium text-primary-600 hover:text-primary-500">
                            {t('orderSummary.continue')}
                            <span aria-hidden="true"> &rarr;</span>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    )
}