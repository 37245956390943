import Api from "./Api";


export class ProductApi extends Api {

    constructor() {
        super();
    }

    async getCouriers() {
        const options = await this.genFetchOptions();
        const url = this.genUrl('/courier',);

        const res = await fetch(url, options);
        return res.json();
    }

}

export default new ProductApi();