import {ApiTranslationList, Translation, TranslationsModel} from "@models/storefront";
import {useQuery} from "@tanstack/react-query";
import {useLocation, useParams} from "react-router-dom";
import {QueryKeys} from "../enums/QueryKeys";
import translationApi from "../utils/TranslationApi";
import {useCallback} from "react";
import {useTranslation as useI18nTranslation} from 'react-i18next';

export const useApiTranslations = () => {
    const {i18n} = useI18nTranslation();

    const params = useParams()
    const location = useLocation();

    const currentKey = params.lang;
    const pathname = location.pathname.substring(`/${currentKey}`.length)

    const href = useCallback((link: string | Translation[], hardLink = false) => {
        let url = location.pathname;
        if (typeof link === 'string') url = link;
        else {
            const translation = link.find((url: Translation) => url.lang === currentKey) ?? link[0];
            if (translation) url = translation.value
        }
        return `/${i18n.language}/${hardLink ? '' : pathname}/${url}`.replace(/\/{2,}/g, '/')
    }, [i18n.language])

    const t = useCallback((values: Translation[] = []) => {
        if (values.length === 0) return ''
        const translation = values.find((value: Translation) => value.lang === currentKey);
        return translation ? translation.value : values[0].value;
    }, [i18n.language])

    return {
        pathname,
        href,
        t,
        ta: t,
        tApi: t,
    }
}

export const useLanguages = () => {
    const {data, isLoading, ...result} = useQuery<ApiTranslationList>(
        [QueryKeys.TRANSLATIONS_LIST],
        () => translationApi.getTranslations(),
    );

    if (isLoading || !data) {
        return {
            data: {
                trans: [{key: 'en', isDefault: true, name: [{lang: 'en', value: 'English'}]}] as TranslationsModel[]
            },
            isLoading,
            ...result
        }
    }

    return {data, isLoading, ...result};
}

export function useTranslations() {
    const translations = useI18nTranslation();
    const {t, ...apiTranslations} = useApiTranslations();
    const languages = useLanguages()


    return {
        ...translations,
        ...apiTranslations,
        apiLanguages: languages,
        languages: languages?.data?.trans,
    }

}