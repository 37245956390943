import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {nl, en} from '@translations/storefront';
import LanguageDetector from 'i18next-browser-languagedetector';

declare module 'react-i18next' {
    interface CustomTypeOptions {
        defaultNS: 'en';
        resources: {
            en: typeof en;
            nl: typeof nl;
        };
    }
}

const resources = {
    en,
    nl
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });