import Api from "./Api";
import {CreateOrder, UpdateCartLineItemBody, UpdateCartShipmentMethod} from "@models/storefront";

export class PurchaseApi extends Api {

    constructor() {
        super();
    }

    acceptCookie = async () => {
        const options = await this.genFetchOptions({method: 'POST'});
        const url = this.genUrl('/accept-cookie');

        const res = await fetch(url, options);
        return res.json();
    }

    getCart = async () => {
        const options = await this.genFetchOptions()
        const url = this.genUrl('/cart');

        const res = await fetch(url, options);
        return res.json()
    }

    updateProductVariantInCart = async (body: UpdateCartLineItemBody) => {
        const options = await this.genFetchOptions({method: 'PATCH', body});
        const url = this.genUrl('/cart/line-item');

        const res = await fetch(url, options);
        return res.json();
    }

    pickShippingMethod = async (body: UpdateCartShipmentMethod) => {
        const options = await this.genFetchOptions({method: 'PATCH', body});
        const url = this.genUrl('/cart/shipment');

        const res = await fetch(url, options);
        return res.json();
    }

    placeOrder = async (body: CreateOrder) => {
        const options = await this.genFetchOptions({method: 'POST', body});
        const url = this.genUrl('/order');

        const res = await fetch(url, options);
        return res.json();
    }

    getPaymentMethods = async () => {
        const options = await this.genFetchOptions();
        const url = this.genUrl('/payment-methods');

        const res = await fetch(url, options);
        return res.json();
    }

}

export default new PurchaseApi();