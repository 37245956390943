import {FaTimes} from "react-icons/fa";
import useCart from "../hooks/useCart";
import {CartLineItemDetail} from "@models/storefront";
import {ProductPicture} from "../components/picture/Picture";
import {useTranslations} from "../hooks/useTranslations";
import {Link} from "react-router-dom";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";
import useConfig from "../hooks/useConfig";
import {RouteTitles} from "../enums/RouteTitles";
import {useTitle} from "react-use";
import InStockLabel from "../components/product/InStockLabel";

export default function CartDetail() {

    const config = useConfig()
    useTitle(`${config.siteName} | ${RouteTitles.CART}`)
    const {
        canCheckout,
        lineItems,
        subTotal,
        shipping,
        tax,
        total,
        updateProductVariantInCart,
        removeProductVariantFromCart
    } = useCart();
    const {t, tApi, href} = useTranslations();
    const currencyFormatter = useCurrencyFormatter()

    return (
        <>
            <div className="pb-24">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl select-none">{t('shoppingCart')}</h1>
                <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                    <section aria-labelledby="cart-heading" className="lg:col-span-7">
                        <h2 id="cart-heading" className="sr-only select-none">
                            {t('cart.itemsInCart')}
                        </h2>

                        <ul role="list" className="divide-y divide-gray-200 border-t border-b border-gray-200">
                            {lineItems.map((lineItem: CartLineItemDetail, productIdx) => (
                                <li key={lineItem.variantSku} className="flex py-6 sm:py-10">
                                    <div className="flex-shrink-0">
                                        <ProductPicture
                                            path={`${lineItem.variant.images[0]}-sm`}
                                            alt={tApi(lineItem.variant.name)}
                                            className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                                        />
                                    </div>

                                    <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                            <div>
                                                <div className="flex justify-between">
                                                    <h3 className="text-sm">
                                                        <Link
                                                            to={href(`products/${tApi(lineItem.variant.url)}`, true)}
                                                            className="font-medium text-gray-700 hover:text-gray-800">
                                                            {tApi(lineItem.variant.name)}
                                                        </Link>
                                                    </h3>
                                                </div>
                                                {/*<div className="mt-1 flex text-sm">*/}
                                                {/*    <p className="text-gray-500">{product.color}</p>*/}
                                                {/*    {product.size ? (*/}
                                                {/*        <p className="ml-4 border-l border-gray-200 pl-4 text-gray-500">{product.size}</p>*/}
                                                {/*    ) : null}*/}
                                                {/*</div>*/}
                                                <p className="mt-1 text-sm font-medium text-gray-900">{currencyFormatter(lineItem.variant.price!)}</p>
                                            </div>

                                            <div className="mt-4 sm:mt-0 sm:pr-9">
                                                <label htmlFor={`quantity-${productIdx}`} className="sr-only">
                                                    {t('quantity')}, {tApi(lineItem.variant.name)}
                                                </label>
                                                <select
                                                    id={`quantity-${productIdx}`}
                                                    name={`quantity-${productIdx}`}
                                                    defaultValue={lineItem.amount}
                                                    onChange={(event) => {
                                                        updateProductVariantInCart({
                                                            variantSku: lineItem.variantSku,
                                                            amount: +event.target.value,
                                                            isNewAmount: true
                                                        })
                                                    }}
                                                    className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                </select>

                                                <div className="absolute top-0 right-0">
                                                    <button
                                                        onClick={() => removeProductVariantFromCart(lineItem.variantSku)}
                                                        className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">{t('remove')}</span>
                                                        <FaTimes className="h-5 w-5" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="mt-4 flex space-x-2 text-sm text-gray-700">
                                            <InStockLabel product={lineItem.product} variant={lineItem.variant}/>
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    {/* Order summary */}
                    <section
                        aria-labelledby="summary-heading"
                        className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                    >
                        <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
                            {t('order.orderSummary')}
                        </h2>

                        <dl className="mt-6 space-y-4">
                            <div className="flex items-center justify-between">
                                <dt className="text-sm text-gray-600">{t('order.subTotal')}</dt>
                                <dd className="text-sm font-medium text-gray-900">{currencyFormatter(subTotal)}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex items-center text-sm text-gray-600">
                                    <span>{t('ship')}</span>
                                    {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">*/}
                                    {/*    <span className="sr-only">Learn more about how shipping is calculated</span>*/}
                                    {/*    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />*/}
                                    {/*</a>*/}
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">{shipping() === -1 ? t('order.selectShippingMethod') : currencyFormatter(total())}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="flex text-sm text-gray-600">
                                    <span>{t('order.taxEstimate')}</span>
                                    {/*<a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">*/}
                                    {/*    <span className="sr-only">Learn more about how tax is calculated</span>*/}
                                    {/*    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />*/}
                                    {/*</a>*/}
                                </dt>
                                <dd className="text-sm font-medium text-gray-900">{currencyFormatter(tax)}</dd>
                            </div>
                            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt className="text-base font-medium text-gray-900">{t('order.total')}</dt>
                                <dd className="text-base font-medium text-gray-900">{currencyFormatter(total())}</dd>
                            </div>
                        </dl>

                        <div className="mt-6 flex">
                            {
                                lineItems.length === 0 || !canCheckout ? (

                                    <button
                                        disabled
                                        className={"w-full text-center rounded-md border border-transparent py-3 px-4 text-base font-medium text-white shadow-sm bg-gray-600 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"}
                                    >
                                        {t('cart.checkout')}
                                    </button>
                                ) : (

                                    <Link
                                        to={href('checkout', true)}
                                        className={"w-full text-center rounded-md border border-transparent bg-primary-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"}
                                    >
                                        {t('cart.checkout')}
                                    </Link>
                                )
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}