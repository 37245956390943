import {useEffect} from 'react';
import {Outlet, useParams} from "react-router-dom";
import TopMenu from "../menu/TopMenu";
import Footer from "../footer/Footer";
import {useTranslations} from "../../hooks/useTranslations";

export default function Container() {

    const {i18n} = useTranslations();

    const params = useParams()

    useEffect(() => {
        i18n.changeLanguage(params.lang)
    }, [params])

    return (
        <>
            <TopMenu/>
            <div className="container py-16 sm:py-24">
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
}