import {Link} from 'react-router-dom';
import {useTitle} from 'react-use';
import useConfig from "../hooks/useConfig";
import {useApiTranslations} from "../hooks/useTranslations";
import Picture from "../components/picture/Picture";
import {useGA} from "../hooks/useGA";


export default function HomeScreen() {
    const config = useConfig()
    useTitle(`${config.siteName}`)
    useGA();

    const {href, tApi} = useApiTranslations();

    return (
        <main>
            <div>
                {/* Hero card */}
                <div className="relative select-none">
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"/>
                    <div className="relative shadow shadow-2xl shadow-black sm:overflow-hidden sm:rounded-2xl">
                        <div className="absolute inset-0">
                            <Picture full
                                     path={'/assets/images/home-hero/home-hero-md'}
                                     alt={'holiday-specials'}
                            />
                            <div className="absolute inset-0 bg-primary-700/30 mix-blend-overlay"/>
                        </div>
                        <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                            <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                                <span className="block text-white shadow-black text-shadow">Happy holidays</span>
                                <span className="block text-primary-200 shadow-black text-shadow">and Seasonal greetings!</span>
                            </h1>
                            <p className="mx-auto mt-6 max-w-lg text-center text-xl text-primary-200 sm:max-w-3xl shadow-black text-shadow">
                                Helemaal klaar voor onze holiday specials!
                            </p>
                            <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                                <div className="">
                                    <Link
                                        to={href('/products')}
                                        className="transition-colors duration-300 flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-3 text-base font-medium text-white outline outline-2 outline-black/50 shadow-sm hover:bg-primary-700 sm:px-8"
                                    >
                                        Bekijken
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* More main page content here... */}
        </main>
    )
}