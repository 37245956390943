import {useQuery} from "@tanstack/react-query";
import {ApiCourierList} from "@models/storefront";
import {QueryKeys} from "../enums/QueryKeys";
import courierApi from "../utils/CourierApi";

export function useCourierList() {
    return useQuery<ApiCourierList>(
        [QueryKeys.COURIERS_LIST],
        () => courierApi.getCouriers(
        ),
        {keepPreviousData: true}
    )
}

// export function useProductDetail(variantUrl: string) {
//     return useQuery<ApiProductDetail>(
//         [QueryKeys.PRODUCT_DETAIL, variantUrl],
//         () => productApi.getProductDetail(variantUrl),
//     )
// }