import {FaFacebook, FaGithub, FaInstagram, FaTwitter} from 'react-icons/fa';
import {IconBaseProps} from 'react-icons/lib';
import {useMemo} from "react";
import {useTranslations} from "../../hooks/useTranslations";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const navigation = {
    main: [
        {name: 'contact', href: 'contact'},
        {name: 'privacyPolicy', href: 'privacy'},
        {name: 'terms', href: 'terms'},
        {name: 'warrantyShippingReturns', href: 'shipping'},
    ],
    social: [
        // {
        //     name: 'Facebook',
        //     href: '#',
        //     icon: (props: JSX.IntrinsicAttributes & IconBaseProps) => (
        //         <FaFacebook {...props}/>
        //     ),
        // },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/SideQuestShop/',
            icon: (props: JSX.IntrinsicAttributes & IconBaseProps) => (
                <FaInstagram {...props}/>
            ),
        },
        // {
        //     name: 'Twitter',
        //     href: '#',
        //     icon: (props: JSX.IntrinsicAttributes & IconBaseProps) => (
        //         <FaTwitter {...props}/>
        //     ),
        // },
        // {
        //     name: 'GitHub',
        //     href: '#',
        //     icon: (props: JSX.IntrinsicAttributes & IconBaseProps) => (
        //         <FaGithub {...props}/>
        //     ),
        // },
    ],
}

export default function Footer() {
    const { href, i18n} = useTranslations();
    const copyrightDate = useMemo(() => new Date().getFullYear(), []);

    const {t} = useTranslation()

    return (
        <footer className="bg-white select-none">
            <div className="container py-12 md:flex md:items-center space-y-4 md:space-y-0">
                <div className="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-6 md:order-2">
                    {navigation.main.map((item) => {
                        return (
                            <div key={item.name} className=" flex flex-1 md:flex-0 justify-center">
                                <Link to={item.href}
                                      className=" px-2 py-1 md:px-5 md:py-2 text-base text-gray-500 hover:text-gray-900">
                                    {t(item.name)}
                                </Link>
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-center items-center space-x-6 md:order-2">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                    ))}
                </div>
                <div className="md:flex-1 md:order-1 md:justify-start">
                    <p className="text-center text-base text-gray-400 md:inline">&copy; {copyrightDate} SideQuestShop</p>
                </div>
            </div>
        </footer>
    )
}