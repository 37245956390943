import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    ApiPaymentMethods,
    ApiCartDetail,
    CartLineItem,
    CartLineItemDetail,
    UpdateCartShipmentMethod, ApiOrderDetail, Order, ApiCreateOrderResponse
} from "@models/storefront";
import purchaseApi from "../utils/PurchaseApi";
import {useCallback, useMemo} from "react";
import {QueryKeys} from "../enums/QueryKeys";
import useCart from "./useCart";
import {useNavigate} from "react-router-dom";
import {useApiTranslations} from "./useTranslations";

export default function useCheckout() {
    const queryClient = useQueryClient();
    const {href} = useApiTranslations()

    const {isLoading, data} = useQuery<ApiPaymentMethods | null>(
        [QueryKeys.PAYMENT_METHOD_LIST],
        purchaseApi.getPaymentMethods
    )
    const {cart} = useCart();

    const navigate = useNavigate();

    const navigateToOrderSummary = useCallback((
        {
            order,
            method,
            issuer
        }: ApiCreateOrderResponse) => {
        navigate(href(`/order/${order.id}?method=${method}${issuer ? `&issuer=${issuer}` : ''}`, true))
    }, [navigate])

    const {mutate: mutatePlaceOrder} = useMutation(purchaseApi.placeOrder, {
        onSuccess: (result: ApiCreateOrderResponse) => {
            navigateToOrderSummary(result)
        },
        onError: (error) => {
        },
        onSettled: () => {
            // TODO: Add invalidate ProductDetail
            queryClient.invalidateQueries([QueryKeys.MY_CART]);
        },
    });

    const {mutate: mutateShipmentMethod} = useMutation(purchaseApi.pickShippingMethod, {
        onSuccess: (result: any) => {
        },
        onError: (error) => {
        },
        onSettled: () => {
            // TODO: Add invalidate ProductDetail
            queryClient.invalidateQueries([QueryKeys.MY_CART]);
        },
    });

    const pickShipmentCourier = useCallback((value: UpdateCartShipmentMethod['courier']) => {
        const oldValue = cart?.courier?.id;
        if (oldValue === value) return;
        const shipmentMethod: UpdateCartShipmentMethod = {
            method: cart?.shipmentMethod?.shipmentMethodId || '',
            courier: value
        }

        mutateShipmentMethod(shipmentMethod);
    }, [cart])

    const pickShipmentMethod = useCallback((value: UpdateCartShipmentMethod['method']) => {
        const shipmentMethod: UpdateCartShipmentMethod = {
            method: value,
            courier: cart?.courier?.id || ''
        }

        mutateShipmentMethod(shipmentMethod);
    }, [cart])

    return {
        isLoading,
        paymentMethods: data && data.methods || [],
        pickShipmentCourier,
        pickShipmentMethod,
        mutatePlaceOrder,
    };
}