import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
    RouterProvider,
    useLoaderData,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import ProductsList from "../screens/ProductsList";
import {TranslationsModel} from "@models/storefront";
import {useEffect} from "react";
import Container from "../components/container/Container";
import ProductDetail from "../screens/ProductDetail";
import CartDetail from "../screens/CartDetail";
import Privacy from "../screens/Privacy";
import Contact from "../screens/Contact";
import Terms from "../screens/Terms";
import HomeScreen from "../screens/HomeScreen";
import {useTranslations} from "../hooks/useTranslations";
import ShippingReturns from "../screens/ShippingReturns";
import CheckoutScreen from "../screens/CheckoutScreen";
import {OrderSummary} from "../screens/OrderSummary";
import translationApi from "../utils/TranslationApi";

export async function TransLoader() {
    let res = await translationApi.getTranslations() as { trans: TranslationsModel[] };
    if (!res.trans) {
        return [{key: 'en', isDefault: true, name: [{lang: 'en', value: 'English'}], enabled: true}]
    }

    return res.trans
}

function TransRouter() {
    const {i18n, languages, apiLanguages} = useTranslations();
    const location = useLocation();
    const params = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        const prevLang = params.lang;
        const newPath = location.pathname.replace(`/${prevLang}/`, `/${i18n.language}/`)
        navigate(newPath);
    }, [i18n])

    useEffect(() => {
        if(apiLanguages.isFetching) return;
        let startsWithTranslation = false
        const i18nTranslationKeys = Object.keys(i18n.store.data);
        const allowedTranslations: TranslationsModel[] = languages.filter((lang: TranslationsModel) => i18nTranslationKeys.includes(lang.key));

        //TODO: Change this to filter between db and file translations and only redirect and allow languages stored in both
        for (const translation of allowedTranslations) {
            if (location.pathname.startsWith(`/${translation.key}`)) startsWithTranslation = true;
        }

        if (!startsWithTranslation) {
            const acceptsLanguageHeader = navigator.language;
            const foundLanguages = allowedTranslations
                .filter((trans: TranslationsModel) => acceptsLanguageHeader.includes(trans.key))
                .map((trans: TranslationsModel) => ({
                    index: acceptsLanguageHeader.indexOf(trans.key),
                    key: trans.key
                }))
                .sort((a, b) => {
                    if (a.index < b.index) return -1;
                    if (a.index > b.index) return 1;
                    return 0;
                })

            let chosenLanguageKey: string;

            if (foundLanguages.length > 0) {
                chosenLanguageKey = foundLanguages[0].key;
            } else {
                const defaultLanguage = languages.find((translation: TranslationsModel) => translation.isDefault);
                if (defaultLanguage) chosenLanguageKey = defaultLanguage.key
                else chosenLanguageKey = languages[0].key;
            }

            const path = location.pathname.replace(/\/[a-zA-Z]{2}\//, '/');

            navigate(`/${chosenLanguageKey}${path}`);
        }
    }, [languages, apiLanguages.isFetching])

    return (<Outlet/>);
}

function ErrorPage() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');

    }, [])
    return (<></>)
}


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            path={"/"}
            element={<TransRouter/>}
            loader={TransLoader}
            errorElement={<ErrorPage/>}
        >
            <Route
                path={"/:lang"}
                element={<Container/>}
            >
                <Route
                    index
                    element={<HomeScreen/>}
                />
                <Route
                    path={"/:lang/products"}
                    element={<ProductsList/>}
                />
                <Route
                    path={"/:lang/products/:variantUrl"}
                    element={<ProductDetail/>}
                />


                <Route
                    path={"/:lang/cart"}
                    element={<CartDetail/>}
                />
                <Route
                    path={"/:lang/checkout"}
                    element={<CheckoutScreen/>}
                />

                <Route
                    path={"/:lang/order/:orderId"}
                    element={<OrderSummary/>}
                />


                <Route
                    path={"/:lang/contact"}
                    element={<Contact/>}
                />
                <Route
                    path={"/:lang/privacy"}
                    element={<Privacy/>}
                />
                <Route
                    path={"/:lang/terms"}
                    element={<Terms/>}
                />
                <Route
                    path={"/:lang/shipping"}
                    element={<ShippingReturns/>}
                />
            </Route>

        </Route>
    )
)


export default function TopRouter() {

    return (
        <RouterProvider router={router}/>
    )

}