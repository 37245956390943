import {useCallback} from "react";
import {useTranslations} from "../hooks/useTranslations";
import {ProductVariant} from "@models/storefront";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";
import {useNavigate, useParams} from "react-router-dom";
import classNames from "classnames";
import {RadioGroup} from '@headlessui/react';
import {ProductPicture} from "../components/picture/Picture";
import {FaShoppingBag} from 'react-icons/fa'
import {useProductDetail, useVariantInStock} from "../hooks/useProduct";
import useCart from "../hooks/useCart";
import CookieRequiredButton from "../components/cookie-required-button/CookieRequiredButton";
import useConfig from "../hooks/useConfig";
import {useTitle} from "react-use";
import {useGA} from "../hooks/useGA";
import InStockLabel from "../components/product/InStockLabel";

export default function ProductDetail() {
    const {tApi, href, t} = useTranslations();
    const currencyFormatter = useCurrencyFormatter();
    const params = useParams<{ variantUrl: string }>()
    const navigate = useNavigate();
    useGA();

    const {isLoading, data,} = useProductDetail(params.variantUrl!)

    const config = useConfig()
    useTitle(`${isLoading || !data ? '' : `${tApi(data.currentVariant.name)} | `} ${config.siteName} `)

    const {updateProductVariantInCart} = useCart();

    const {inStock} = useVariantInStock(data?.product, data?.currentVariant);

    const selectVariant = useCallback((url: string) => {
        navigate(url);
    }, [navigate])

    if (isLoading) {
        return <></>
    }

    if (!data || !data.product || !data.currentVariant) {
        navigate('/products')
        return <></>
    }

    return (
        <>
            <div
                className="lg:grid lg:grid-cols-2 lg:gap-x-8 ">

                {/* Product image */}
                <div className="mt-10 lg:col-start-1 lg:row-span-2 lg:mt-0 lg:self-start">
                    <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
                        <ProductPicture full
                                        path={`${data.currentVariant.images[0]}-md`}
                                        alt={tApi(data.currentVariant.name)}
                        />
                    </div>
                </div>

                {/* Product details */}
                <div className="">
                    {/*<nav aria-label="Breadcrumb">*/}
                    {/*    <ol role="list" className="flex items-center space-x-2">*/}
                    {/*        {product.breadcrumbs.map((breadcrumb, breadcrumbIdx) => (*/}
                    {/*            <li key={breadcrumb.id}>*/}
                    {/*                <div className="flex items-center text-sm">*/}
                    {/*                    <a href={breadcrumb.href} className="font-medium text-gray-500 hover:text-gray-900">*/}
                    {/*                        {breadcrumb.name}*/}
                    {/*                    </a>*/}
                    {/*                    {breadcrumbIdx !== product.breadcrumbs.length - 1 ? (*/}
                    {/*                        <svg*/}
                    {/*                            viewBox="0 0 20 20"*/}
                    {/*                            xmlns="http://www.w3.org/2000/svg"*/}
                    {/*                            fill="currentColor"*/}
                    {/*                            aria-hidden="true"*/}
                    {/*                            className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"*/}
                    {/*                        >*/}
                    {/*                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />*/}
                    {/*                        </svg>*/}
                    {/*                    ) : null}*/}
                    {/*                </div>*/}
                    {/*            </li>*/}
                    {/*        ))}*/}
                    {/*    </ol>*/}
                    {/*</nav>*/}

                    <div className="mt-12 lg:mt-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{tApi(data.product.name)}</h1>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-3xl">{tApi(data.currentVariant.name)}</h2>
                    </div>

                    <div className="flex flex-col divide-y">
                        <section aria-labelledby="information-heading" className="py-4">
                            <h2 id="information-heading" className="sr-only">
                                {t('product.details.productInformationLabel')}
                            </h2>

                            <div className="flex items-center">
                                <p className="text-lg text-gray-900 sm:text-xl">{currencyFormatter(data.currentVariant.price!)}</p>

                                <div className="ml-4 border-l border-gray-300 pl-4">
                                    <h2 className="sr-only">{t('product.detail.review')}</h2>
                                    <div className="flex items-center">
                                        <div>
                                            <div className="flex items-center">
                                                {/*    {[0, 1, 2, 3, 4].map((rating) => (*/}
                                                {/*        <i  */}
                                                {/*            key={rating}*/}
                                                {/*            className={classNames(*/}
                                                {/*                "fa fa-star",*/}
                                                {/*                // reviews.average > rating ? 'text-yellow-400' : 'text-gray-300',*/}
                                                {/*                'h-5 w-5 flex-shrink-0'*/}
                                                {/*            )}*/}
                                                {/*            aria-hidden="true"*/}
                                                {/*        />*/}
                                                {/*    ))}*/}
                                                {/*</div>*/}
                                                {/*<p className="sr-only">{reviews.average} out of 5 stars</p>*/}
                                            </div>
                                        </div>
                                        {/*<p className="ml-2 text-sm text-gray-500">{reviews.totalCount} reviews</p>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 space-y-6">
                                <p className="text-base text-gray-500">{tApi(data.product.description)}</p>
                            </div>

                            <div className="mt-6 flex items-center select-none">
                                <InStockLabel product={data.product} variant={data.currentVariant}/>
                            </div>
                        </section>
                        {/*</div>*/}


                        {/*/!* Product form *!/*/}
                        {/*<div className="mt-10 lg:col-start-2 lg:row-start-2 lg:max-w-lg lg:self-start">*/}
                        {data.product.variants.length > 1 && (
                            <>
                                <section aria-labelledby="options-heading" className="py-4">
                                    <h2 id="options-heading" className="sr-only">
                                        {t('product.detail.options')}
                                    </h2>

                                    <div className="sm:flex sm:justify-between">
                                        {/* Size selector */}
                                        <RadioGroup value={href(`products/${tApi(data.currentVariant.url)}`, true)}
                                                    onChange={selectVariant}>
                                            <RadioGroup.Label
                                                className="block text-sm font-medium text-gray-700">Size</RadioGroup.Label>
                                            <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                                {data.product.variants.map((variant: ProductVariant) => (
                                                    <RadioGroup.Option
                                                        as="div"
                                                        key={variant.sku}
                                                        value={href(`products/${tApi(variant.url)}`, true)}
                                                        className={({active}) =>
                                                            classNames(
                                                                active ? 'ring-2 ring-primary-500' : '',
                                                                'relative block cursor-pointer rounded-lg border border-gray-300 p-4 focus:outline-none'
                                                            )
                                                        }
                                                    >
                                                        {({active, checked}) => (
                                                            <>
                                                                <RadioGroup.Label as="p"
                                                                                  className="text-base font-medium text-gray-900">
                                                                    {tApi(variant.name)}
                                                                </RadioGroup.Label>
                                                                <RadioGroup.Description as="p"
                                                                                        className="mt-1 text-sm text-gray-500">
                                                                    {tApi(variant.description)}
                                                                </RadioGroup.Description>
                                                                <div
                                                                    className={classNames(
                                                                        active ? 'border' : 'border-2',
                                                                        checked ? 'border-primary-500' : 'border-transparent',
                                                                        'pointer-events-none absolute -inset-px rounded-lg'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                            </div>
                                        </RadioGroup>
                                    </div>
                                    {/*<div className="mt-4">*/}
                                    {/*    <a href="#" className="group inline-flex text-sm text-gray-500 hover:text-gray-700">*/}
                                    {/*        <span>What size should I buy?</span>*/}
                                    {/*        <FaCheckCircle*/}
                                    {/*            className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"*/}
                                    {/*            aria-hidden="true"*/}
                                    {/*        />*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                </section>
                            </>
                        )}

                        <section aria-labelledby="submit" className="py-4">
                            {inStock ? (
                                <CookieRequiredButton
                                    onClick={() => updateProductVariantInCart({
                                        amount: 1,
                                        variantSku: data.currentVariant.sku
                                    })}
                                    className="flex gap-4 w-full items-center justify-center rounded-md border border-transparent bg-primary-600 py-3 px-8 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                >
                                    <FaShoppingBag className={""}/>
                                    {t('cart.add')}
                                </CookieRequiredButton>
                            ) : (
                                <button
                                    disabled
                                    className={"w-full text-center rounded-md border border-transparent py-3 px-4 text-base font-medium text-white shadow-sm bg-gray-600 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"}
                                >
                                    {t('cart.outOfStock')}
                                </button>
                            )}
                        </section>
                    </div>
                    {/*<div className="mt-6 text-center">*/}
                    {/*    <a href="#" className="group inline-flex text-base font-medium">*/}
                    {/*        <i*/}
                    {/*            className="fa fa-shield mr-2 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"*/}
                    {/*            aria-hidden="true"*/}
                    {/*        />*/}
                    {/*        <span className="text-gray-500 hover:text-gray-700">Lifetime Guarantee</span>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )


}