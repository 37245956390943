import {Fragment, useCallback, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {Dialog, Popover, Tab, Transition} from '@headlessui/react'
import {Bars3Icon, ShoppingBagIcon, XMarkIcon} from '@heroicons/react/24/outline'
import classNames from "classnames";
import {HiChevronDown} from "react-icons/hi2";
import useCart from "../../hooks/useCart";
import {useTranslations} from "../../hooks/useTranslations";
import {useCurrencyFormatter} from "../../hooks/useCurrencyFormatter";

type NavigationCategoryFeature = {
    name: string;
    href: string;
    imageSrc: string;
    imageAlt: string;
}
type NavigationCategorySections = {
    id: string;
    name: string;
    items: Array<{ name: string; href: string }>

}

type NavigationPage = {
    name: string;
    href: string;
}

type NavigationCategories = {
    id: string;
    name: string;
    featured: NavigationCategoryFeature[];
    sections: NavigationCategorySections[];
}

type Navigation = {
    categories: NavigationCategories[],
    pages: NavigationPage[];
}

const currencies = [
    // 'CAD',
    // 'USD',
    // 'AUD',
    'EUR',
    // 'GBP'
]

const navigation: Navigation = {
    categories: [
        // {
        //     id: 'products',
        //     name: 'Products',
        //     featured: [
        //     //     {
        //     //         name: 'Handmade',
        //     //         href: '#',
        //     //                     imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
        //     //                     imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
        //     //     }
        //     ],
        //     sections: [
        //         {
        //             id: 'handmade',
        //             name: 'Handmade',
        //             href: '#',
        //         }
        //     ],
        // }
        // {
        //     id: 'women',
        //     name: 'Women',
        //     featured: [
        //         {
        //             name: 'New Arrivals',
        //             href: '#',
        //             imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
        //             imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
        //         },
        //         {
        //             name: 'Basic Tees',
        //             href: '#',
        //             imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
        //             imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
        //         },
        //     ],
        //     sections: [
        //         {
        //             id: 'clothing',
        //             name: 'Clothing',
        //             items: [
        //                 { name: 'Tops', href: '#' },
        //                 { name: 'Dresses', href: '#' },
        //                 { name: 'Pants', href: '#' },
        //                 { name: 'Denim', href: '#' },
        //                 { name: 'Sweaters', href: '#' },
        //                 { name: 'T-Shirts', href: '#' },
        //                 { name: 'Jackets', href: '#' },
        //                 { name: 'Activewear', href: '#' },
        //                 { name: 'Browse All', href: '#' },
        //             ],
        //         },
        //         {
        //             id: 'accessories',
        //             name: 'Accessories',
        //             items: [
        //                 { name: 'Watches', href: '#' },
        //                 { name: 'Wallets', href: '#' },
        //                 { name: 'Bags', href: '#' },
        //                 { name: 'Sunglasses', href: '#' },
        //                 { name: 'Hats', href: '#' },
        //                 { name: 'Belts', href: '#' },
        //             ],
        //         },
        //         {
        //             id: 'brands',
        //             name: 'Brands',
        //             items: [
        //                 { name: 'Full Nelson', href: '#' },
        //                 { name: 'My Way', href: '#' },
        //                 { name: 'Re-Arranged', href: '#' },
        //                 { name: 'Counterfeit', href: '#' },
        //                 { name: 'Significant Other', href: '#' },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     id: 'men',
        //     name: 'Men',
        //     featured: [
        //         {
        //             name: 'New Arrivals',
        //             href: '#',
        //             imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
        //             imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
        //         },
        //         {
        //             name: 'Artwork Tees',
        //             href: '#',
        //             imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
        //             imageAlt:
        //                 'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
        //         },
        //     ],
        //     sections: [
        //         {
        //             id: 'clothing',
        //             name: 'Clothing',
        //             items: [
        //                 { name: 'Tops', href: '#' },
        //                 { name: 'Pants', href: '#' },
        //                 { name: 'Sweaters', href: '#' },
        //                 { name: 'T-Shirts', href: '#' },
        //                 { name: 'Jackets', href: '#' },
        //                 { name: 'Activewear', href: '#' },
        //                 { name: 'Browse All', href: '#' },
        //             ],
        //         },
        //         {
        //             id: 'accessories',
        //             name: 'Accessories',
        //             items: [
        //                 { name: 'Watches', href: '#' },
        //                 { name: 'Wallets', href: '#' },
        //                 { name: 'Bags', href: '#' },
        //                 { name: 'Sunglasses', href: '#' },
        //                 { name: 'Hats', href: '#' },
        //                 { name: 'Belts', href: '#' },
        //             ],
        //         },
        //         {
        //             id: 'brands',
        //             name: 'Brands',
        //             items: [
        //                 { name: 'Re-Arranged', href: '#' },
        //                 { name: 'Counterfeit', href: '#' },
        //                 { name: 'Full Nelson', href: '#' },
        //                 { name: 'My Way', href: '#' },
        //             ],
        //         },
        //     ],
        // },
    ],
    pages: [
        {name: 'products.plural', href: '/products'},
        // { name: 'Contact', href: '/contact' },
        // { name: 'Contact', href: '/privacy' },
    ],
}

function MobileMenu({
                        open,
                        setOpen,
                        changeLanguage
                    }: { open: boolean, setOpen: (val: boolean) => void, changeLanguage: (lang: string) => void }) {
    const {tApi, t, languages, i18n, href} = useTranslations();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"/>
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel
                            className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                            <div className="flex px-4 pt-5 pb-2">
                                <button
                                    type="button"
                                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                </button>
                            </div>

                            {/* Links */}
                            <Tab.Group as="div" className="mt-2">
                                <div className="border-b border-gray-200">
                                    <Tab.List className="-mb-px flex space-x-8 px-4">
                                        {navigation.categories.map((category) => (
                                            <Tab
                                                key={category.name}
                                                className={({selected}) =>
                                                    classNames(
                                                        selected ? 'text-primary-600 border-primary-600' : 'text-gray-900 border-transparent',
                                                        'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium'
                                                    )
                                                }
                                            >
                                                {category.name}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                    {navigation.categories.map((category) => (
                                        <Tab.Panel key={category.name} className="space-y-12 px-4 py-6">
                                            <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                                                {category.featured.map((item) => (
                                                    <div key={item.name} className="group relative">
                                                        <div
                                                            className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                            <img src={item.imageSrc} alt={item.imageAlt}
                                                                 className="object-cover object-center"/>
                                                        </div>
                                                        <a href={item.href}
                                                           className="mt-6 block text-sm font-medium text-gray-900">
                                                            <span className="absolute inset-0 z-10" aria-hidden="true"/>
                                                            {item.name}
                                                        </a>
                                                        <p aria-hidden="true" className="mt-1 text-sm text-gray-500">
                                                            Shop now
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>

                            <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                                {navigation.pages.map((page) => (
                                    <div key={page.name} className="flow-root">
                                        <Link to={href(page.href, true)}
                                              className="-m-2 block p-2 font-medium text-gray-900">
                                            {t(page.name)}
                                        </Link>
                                    </div>
                                ))}
                            </div>

                            {/*<div className="space-y-6 border-t border-gray-200 py-6 px-4">*/}
                            {/*    <div className="flow-root">*/}
                            {/*        <a href="#" className="-m-2 block p-2 font-medium text-gray-900">*/}
                            {/*            Create an account*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*    <div className="flow-root">*/}
                            {/*        <a href="#" className="-m-2 block p-2 font-medium text-gray-900">*/}
                            {/*            Sign in*/}
                            {/*        </a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="space-y-6 border-t border-gray-200 py-6 px-4">*/}
                            {/*    /!* Language selector *!/*/}
                            {/*    /!*<form>*!/*/}
                            {/*    <div className="inline-block">*/}
                            {/*        <label htmlFor="mobile-language" className="sr-only">*/}
                            {/*            Languages*/}
                            {/*        </label>*/}
                            {/*        <div*/}
                            {/*            className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">*/}
                            {/*            <select*/}
                            {/*                defaultValue={i18n.language}*/}
                            {/*                value={i18n.language}*/}
                            {/*                onChange={(event) => {*/}
                            {/*                    changeLanguage(event.target.value)*/}
                            {/*                }}*/}
                            {/*                id="mobile-language"*/}
                            {/*                name="lang"*/}
                            {/*                className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"*/}
                            {/*            >*/}
                            {/*                {languages.map((translation) => (*/}
                            {/*                    <option key={translation.key}*/}
                            {/*                            value={translation.key}>{tApi(translation.name)}</option>*/}
                            {/*                ))}*/}
                            {/*            </select>*/}
                            {/*            <div*/}
                            {/*                className="pointer-events-none absolute inset-y-0 right-0 flex items-center">*/}
                            {/*                <HiChevronDown className="h-5 w-5 text-gray-500" aria-hidden="true"/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    /!*</form>*!/*/}
                            {/*</div>*/}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default function TopMenu() {
    const [open, setOpen] = useState(false);
    const {totalItemsCount, lineItems} = useCart();
    const currencyFormatter = useCurrencyFormatter();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {tApi, t, languages, href, i18n} = useTranslations();

    const changeLanguage = useCallback((newLang: string) => {
        const prevLang = params.lang;
        const newPath = location.pathname.replace(`/${prevLang}/`, `/${newLang}/`)
        navigate(newPath);
    }, [params])

    return (
        <div className="bg-white select-none">
            {/* Mobile menu */}
            <MobileMenu open={open} setOpen={setOpen} changeLanguage={changeLanguage}/>

            <header className="relative">
                <nav aria-label="Top">
                    {/* Top navigation */}
                    <div className="bg-gray-900">
                        <div className="mx-auto flex h-10 container items-center justify-between ">
                            {/* Currency selector */}
                            {/*<form>*/}
                                <div>
                                    <label htmlFor="desktop-language" className="sr-only">
                                        Language
                                    </label>
                                    <div
                                        className="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
                                        <select
                                            // defaultValue={i18n.language}
                                            onChange={(event) => {
                                                changeLanguage(event.target.value)
                                            }}
                                            value={i18n.language}
                                            id="desktop-language"
                                            name="language"
                                            className="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                                        >
                                            {languages.map((language) => (
                                                <option key={language.key}
                                                        value={language.key}>{tApi(language.name)}</option>
                                            ))}
                                        </select>
                                        <div
                                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                            <HiChevronDown className="h-5 w-5 text-gray-300" aria-hidden="true"/>
                                        </div>
                                    </div>
                                </div>
                            {/*</form>*/}
                            {
                                false && (
                                    <div className="flex items-center space-x-6">
                                        <a href="#" className="text-sm font-medium text-white hover:text-gray-100">
                                            {t('headerBar.signIn')}
                                        </a>
                                        <a href="#" className="text-sm font-medium text-white hover:text-gray-100">
                                            {t('headerBar.createAccount')}
                                        </a>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    {/* Secondary navigation */}
                    <div className="sticky top-0">
                        <div className="mx-auto container ">
                            <div className="border-b border-gray-200">
                                <div className="flex h-16 items-center justify-between">
                                    {/* Logo (lg+) */}
                                    <div className="hidden lg:flex lg:items-center">
                                        <Link to={href('/', true)} className="lg:mr-8">
                                            <span className="sr-only">Your Company</span>
                                            <img
                                                className="h-8 w-auto"
                                                src="/assets/svg/logo.svg"
                                                alt=""
                                            />
                                        </Link>
                                    </div>

                                    <div className="hidden h-full lg:flex lg:flex-1">
                                        {/* Flyout menus */}
                                        <Popover.Group className="inset-x-0 flex-1 bottom-0 px-4">
                                            <div className="flex h-full justify-end space-x-8">
                                                {navigation.categories.map((category) => (
                                                    <Popover key={category.name} className="flex">
                                                        {({open}) => (
                                                            <>
                                                                <div className="relative flex">
                                                                    <Popover.Button
                                                                        className={classNames(
                                                                            open
                                                                                ? 'border-primary-600 text-primary-600'
                                                                                : 'border-transparent text-gray-700 hover:text-gray-800',
                                                                            'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out'
                                                                        )}
                                                                    >
                                                                        {category.name}
                                                                    </Popover.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="opacity-0"
                                                                    enterTo="opacity-100"
                                                                    leave="transition ease-in duration-150"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Popover.Panel
                                                                        className="absolute inset-x-0 top-full text-sm text-gray-500">
                                                                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                                        <div
                                                                            className="absolute inset-0 top-1/2 bg-white shadow"
                                                                            aria-hidden="true"/>

                                                                        <div className="relative bg-white">
                                                                            <div className="mx-auto container ">
                                                                                <div
                                                                                    className="grid grid-cols-4 gap-y-10 gap-x-8 py-16">
                                                                                    {category.featured.map((item) => (
                                                                                        <div key={item.name}
                                                                                             className="group relative">
                                                                                            <div
                                                                                                className="aspect-w-1 aspect-h-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                                                                <img
                                                                                                    src={item.imageSrc}
                                                                                                    alt={item.imageAlt}
                                                                                                    className="object-cover object-center"
                                                                                                />
                                                                                            </div>
                                                                                            <a href={item.href}
                                                                                               className="mt-4 block font-medium text-gray-900">
                                                                                                <span
                                                                                                    className="absolute inset-0 z-10"
                                                                                                    aria-hidden="true"/>
                                                                                                {item.name}
                                                                                            </a>
                                                                                            <p aria-hidden="true"
                                                                                               className="mt-1">
                                                                                                Shop now
                                                                                            </p>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Popover.Panel>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Popover>
                                                ))}

                                                {navigation.pages.map((page) => (
                                                    <Link
                                                        key={page.name}
                                                        to={href(page.href, true)}
                                                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                                                    >
                                                        {t(page.name)}
                                                    </Link>
                                                ))}
                                            </div>
                                        </Popover.Group>
                                    </div>

                                    {/* Mobile menu and search (lg-) */}
                                    <div className="flex flex-1 items-center lg:hidden">
                                        <button
                                            type="button"
                                            className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                                            onClick={() => setOpen(true)}
                                        >
                                            <span className="sr-only">Open menu</span>
                                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                                        </button>

                                        {/* Search */}
                                        {/*<a href="#" className="ml-2 p-2 text-gray-400 hover:text-gray-500">*/}
                                        {/*    <span className="sr-only">Search</span>*/}
                                        {/*    <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />*/}
                                        {/*</a>*/}
                                    </div>

                                    {/* Logo (lg-) */}
                                    <Link to={href('/', true)} className="lg:hidden">
                                        <span className="sr-only">SideQuest</span>
                                        <img
                                            src="/assets/svg/logo.svg"
                                            alt=""
                                            className="h-8 w-auto"
                                        />
                                    </Link>

                                    <div className="flex flex-1 lg:grow-0 items-center justify-end">
                                        {/*<a href="#" className="hidden text-sm font-medium text-gray-700 hover:text-gray-800 lg:block">*/}
                                        {/*    Search*/}
                                        {/*</a>*/}

                                        <div className="flex items-center lg:ml-8">
                                            {/* Help */}
                                            {/*<a href="#" className="p-2 text-gray-400 hover:text-gray-500 lg:hidden">*/}
                                            {/*    <span className="sr-only">Help</span>*/}
                                            {/*    <HiOutlineQuestionMarkCircle  className="h-6 w-6" aria-hidden="true" />*/}
                                            {/*</a>*/}
                                            {/*<a href="/contact" className="hidden text-sm font-medium text-gray-700 hover:text-gray-800 lg:block">*/}
                                            {/*    Contact*/}
                                            {/*</a>*/}

                                            {/* Cart */}
                                            <div className="ml-4 flow-root lg:ml-8">
                                                <Link to={href("/cart", true)}
                                                      className="group -m-2 flex items-center p-2">
                                                    <ShoppingBagIcon
                                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{totalItemsCount}</span>
                                                    <span className="sr-only">items in cart, view bag</span>
                                                </Link>
                                            </div>

                                            {/*<Popover className="ml-4  text-sm lg:relative lg:ml-8">*/}
                                            {/*    <Popover.Button className="group -m-2 flex items-center p-2">*/}
                                            {/*        <ShoppingBagIcon*/}
                                            {/*            className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"*/}
                                            {/*            aria-hidden="true"*/}
                                            {/*        />*/}
                                            {/*        <span*/}
                                            {/*            className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{totalItemsCount}</span>*/}
                                            {/*        <span className="sr-only">items in cart, view bag</span>*/}
                                            {/*    </Popover.Button>*/}
                                            {/*    <Transition*/}
                                            {/*        as={Fragment}*/}
                                            {/*        enter="transition ease-out duration-200"*/}
                                            {/*        enterFrom="opacity-0"*/}
                                            {/*        enterTo="opacity-100"*/}
                                            {/*        leave="transition ease-in duration-150"*/}
                                            {/*        leaveFrom="opacity-100"*/}
                                            {/*        leaveTo="opacity-0"*/}
                                            {/*    >*/}
                                            {/*        <Popover.Panel*/}
                                            {/*            className="absolute inset-x-0 top-16 mt-px bg-white pb-6 shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5 z-10">*/}
                                            {/*            <h2 className="sr-only">Shopping Cart</h2>*/}

                                            {/*            <div className="mx-auto max-w-2xl px-4">*/}
                                            {/*                <ul role="list" className="divide-y divide-gray-200">*/}
                                            {/*                    {lineItems.map((lineItem: CartLineItemDetail) => (*/}
                                            {/*                        <li key={lineItem.variantSku}>*/}
                                            {/*                            <Link*/}
                                            {/*                                to={href(`products/${t(lineItem.variant.url)}`, true)}*/}
                                            {/*                                className="flex items-center py-6">*/}
                                            {/*                                <div*/}
                                            {/*                                    className={"flex-none rounded-md border border-gray-200"}>*/}
                                            {/*                                    <ProductPicture*/}
                                            {/*                                        isThumbnail*/}
                                            {/*                                        path={lineItem.variant.images[0]}*/}
                                            {/*                                        alt={t(lineItem.variant.name)}*/}
                                            {/*                                        className="h-16 w-16 rounded-md"*/}
                                            {/*                                    />*/}
                                            {/*                                </div>*/}
                                            {/*                                <div className="ml-4 flex-auto">*/}
                                            {/*                                    <h3 className="font-medium text-gray-900">*/}
                                            {/*                                        {t(lineItem.variant.name)}*/}
                                            {/*                                    </h3>*/}
                                            {/*                                    <span*/}
                                            {/*                                        className="font-light text-gray-900">*/}
                                            {/*                                    {currencyFormatter(lineItem.variant.price!)}*/}
                                            {/*                                </span>*/}
                                            {/*                                    /!*<p className="text-gray-500">{lineItem.color}</p>*!/*/}
                                            {/*                                </div>*/}
                                            {/*                            </Link>*/}
                                            {/*                        </li>*/}
                                            {/*                    ))}*/}
                                            {/*                </ul>*/}

                                            {/*                <button*/}
                                            {/*                    type="submit"*/}
                                            {/*                    className="w-full rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-50"*/}
                                            {/*                >*/}
                                            {/*                    Checkout*/}
                                            {/*                </button>*/}

                                            {/*                <p className="mt-6 text-center">*/}
                                            {/*                    <a href="#"*/}
                                            {/*                       className="text-sm font-medium text-primary-600 hover:text-primary-500">*/}
                                            {/*                        View Shopping Bag*/}
                                            {/*                    </a>*/}
                                            {/*                </p>*/}
                                            {/*            </div>*/}
                                            {/*        </Popover.Panel>*/}
                                            {/*    </Transition>*/}
                                            {/*</Popover>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}