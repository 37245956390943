import {useTitle} from "react-use";
import useConfig from "../hooks/useConfig";
import {RouteTitles} from "../enums/RouteTitles";

export default function Privacy() {
    const config = useConfig()
    useTitle(`${config.siteName} | ${RouteTitles.PRIVACY}`)


    return (
        <>
            <div>
                <div className="card w-full h-full  text-neutral-content">
                    <div className="card-body gap-0 prose text-neutral-content">
                        <h1 className="prose-2xl text-neutral-content">Privacybeleid SideQuestShop</h1>
                        <span>https://sidequestshop.nl</span>

                        <h2 className={"prose-xl text-neutral-content"}>Over ons privacybeleid</h2>
                        <p>
                            SideQuestShop geeft veel om uw privacy. Wij verwerken daarom uitsluitend gegevens die wij
                            nodig
                            hebben voor (het
                            verbeteren van) onze dienstverlening en gaan zorgvuldig om met de informatie die wij over u
                            en
                            uw gebruik van
                            onze diensten hebben verzameld. Wij stellen uw gegevens nooit voor commerciële
                            doelstellingen
                            ter beschikking aan
                            derden.
                        </p>
                        <p>
                            Dit privacybeleid is van toepassing op het gebruik van de website en de daarop ontsloten
                            dienstverlening van
                            SideQuestShop. De ingangsdatum voor de geldigheid van deze voorwaarden is 25/10/2022, met
                            het
                            publiceren van
                            een nieuwe versie vervalt de geldigheid van alle voorgaande versies. Dit privacybeleid
                            beschrijft welke gegevens
                            over u door ons worden verzameld, waar deze gegevens voor worden gebruikt en met wie en
                            onder
                            welke
                            voorwaarden deze gegevens eventueel met derden kunnen worden gedeeld. Ook leggen wij aan u
                            uit
                            op welke wijze
                            wij uw gegevens opslaan en hoe wij uw gegevens tegen misbruik beschermen en welke rechten u
                            heeft met
                            betrekking tot de door u aan ons verstrekte persoonsgegevens.
                        </p>
                        <p>
                            Als u vragen heeft over ons privacybeleid kunt u contact opnemen met onze contactpersoon
                            voor
                            privacyzaken, u
                            vindt de contactgegevens aan het einde van ons privacybeleid.
                        </p>

                        <h2 className={"prose-xl text-neutral-content"}>Over de gegevensverwerking</h2>
                        <p>
                            Hieronder kan u lezen op welke wijze wij uw gegevens verwerken, waar wij deze (laten)
                            opslaan,
                            welke
                            beveiligingstechnieken wij gebruiken en voor wie de gegevens inzichtelijk zijn.
                        </p>

                        <h3 className="prose-lg text-neutral-content">Webwinkelsoftware</h3>
                        <h4 className="text-neutral-content">3T Development Notores Ecomemrce</h4>
                        <p>
                            Onze webwinkel is ontwikkeld met software van 3T Development. Persoonsgegevens die u ten
                            behoeve
                            van onze
                            dienstverlening aan ons beschikbaar stelt, worden met deze partij gedeeld. 3T Development
                            heeft
                            toegang tot uw
                            gegevens om ons (technische) ondersteuning te bieden, zij zullen uw gegevens nooit gebruiken
                            voor een ander
                            doel. 3T Development is op basis van de overeenkomst die wij met hen hebben gesloten
                            verplicht
                            om passende
                            beveiligingsmaatregelen te nemen. 3T Development maakt gebruik van cookies om technische
                            informatie te
                            verzamelen met betrekking tot uw gebruik van de software, er worden geen persoonsgegevens
                            verzameld en/of
                            opgeslagen.
                        </p>

                        <h3 className="prose-lg text-neutral-content">Email en mailinglijsten</h3>
                        <h4 className="text-neutral-content">MailChimp</h4>
                        <p>
                            Wij versturen onze e-mail nieuwsbrieven met MailChimp. MailChimp zal uw naam en e-mailadres
                            nooit voor eigen
                            doeleinden gebruiken. Onderaan elke e-mail die geautomatiseerd via onze website is verzonden
                            ziet u de
                            ‘unsubscribe’ link. U ontvangt onze nieuwsbrief dan niet meer. Uw persoonsgegevens worden
                            door
                            MailChimp
                            beveiligd opgeslagen. MailChimp maakt gebruik van cookies en andere internettechnologieën
                            die
                            inzichtelijk
                            maken of e-mails worden geopend en gelezen. MailChimp behoudt zich het recht voor om uw
                            gegevens
                            te
                            gebruiken voor het verder verbeteren van de dienstverlening en in het kader daarvan
                            informatie
                            met derden te
                            delen.
                        </p>
                        <h4 className="text-neutral-content">Gmail</h4>
                        <p>
                            Wij maken voor ons reguliere zakelijke e-mailverkeer gebruik van de diensten van Gmail. Deze
                            partij heeft
                            passende technische en organisatorische maatregelen getroffen om misbruik, verlies en
                            corruptie
                            van uw en onze
                            gegevens zoveel mogelijk te voorkomen. Gmail heeft geen toegang tot ons postvak en wij
                            behandelen al ons emailverkeer vertrouwelijk.
                        </p>

                        <h3 className="prose-lg text-neutral-content">Payment processors</h3>
                        <h4 className="text-neutral-content">Mollie</h4>
                        <p>
                            Voor het afhandelen van een (deel van) de betalingen in onze webwinkel maken wij gebruik van
                            het
                            platform van
                            Mollie. Mollie verwerkt uw naam, adres en woonplaatsgegevens en uw betaalgegevens zoals uw
                            bankrekening- of
                            creditcardnummer. Mollie heeft passende technische en organisatorische maatregelen genomen
                            om uw
                            persoonsgegevens te beschermen. Mollie behoudt zich het recht voor uw gegevens te gebruiken
                            om
                            de
                            dienstverlening verder te verbeteren en in het kader daarvan (geanonimiseerde) gegevens met
                            derden te delen.
                            Alle hierboven genoemde waarborgen met betrekking tot de bescherming van uw persoonsgegevens
                            zijn eveneens
                            van toepassing op de onderdelen van Mollie’s dienstverlening waarvoor zij derden
                            inschakelen.
                            Mollie bewaart uw
                            gegevens niet langer dan op grond van de wettelijke termijnen is toegestaan.
                        </p>

                        {/*<h3 className="prose-lg text-neutral-content">Beoordelingen</h3>*/}
                        <h3 className="prose-lg text-neutral-content">Verzend en logistiek</h3>
                        <h4 className="text-neutral-content">PostNL</h4>
                        <p>
                            Als u een bestelling bij ons plaatst is het onze taak om uw pakket bij u te laten bezorgen.
                            Wij
                            maken gebruik van
                            de diensten van PostNL voor het uitvoeren van de leveringen. Het is daarvoor noodzakelijk
                            dat
                            wij uw naam,
                            adres en woonplaatsgegevens met PostNL delen. PostNL gebruikt deze gegevens alleen ten
                            behoeve
                            van het
                            uitvoeren van de overeenkomst. In het geval dat PostNL onderaannemers inschakelt, stelt
                            PostNL
                            uw gegevens
                            ook aan deze partijen ter beschikking.
                        </p>
                        <h4 className="text-neutral-content">DHL</h4>
                        <p>
                            Als u een bestelling bij ons plaatst is het onze taak om uw pakket bij u te laten bezorgen.
                            Wij
                            maken gebruik van
                            de diensten van DHL voor het uitvoeren van de leveringen. Het is daarvoor noodzakelijk dat
                            wij
                            uw naam, adres
                            en woonplaatsgegevens met DHL delen. DHL gebruikt deze gegevens alleen ten behoeve van het
                            uitvoeren van de
                            overeenkomst. In het geval dat DHL onderaannemers inschakelt, stelt DHL uw gegevens ook aan
                            deze
                            partijen ter
                            beschikking.
                        </p>

                        <h2 className={" prose-xl text-neutral-content"}>Doel van de gegevensverwerking</h2>
                        <h3 className="prose-lg text-neutral-content">Algemeen doel van de verwerking</h3>
                        <p>

                            Wij gebruiken uw gegevens uitsluitend ten behoeve van onze dienstverlening. Dat wil zeggen
                            dat
                            het doel van de
                            verwerking altijd direct verband houdt met de opdracht die u verstrekt. Wij gebruiken uw
                            gegevens niet voor
                            (gerichte) marketing. Als u gegevens met ons deelt en wij gebruiken deze gegevens om -
                            anders
                            dan op uw
                            verzoek - op een later moment contact met u op te nemen, vragen wij u hiervoor expliciet
                            toestemming. Uw
                            gegevens worden niet met derden gedeeld, anders dan om aan boekhoudkundige en overige
                            administratieve
                            verplichtingen te voldoen. Deze derden zijn allemaal tot geheimhouding gehouden op grond van
                            de
                            overeenkomst
                            tussen hen en ons of een eed of wettelijke verplichting.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Automatisch verzamelde gegevens</h3>
                        <p>
                            Gegevens die automatisch worden verzameld door onze website worden verwerkt met het doel
                            onze
                            dienstverlening verder te verbeteren. Deze gegevens (bijvoorbeeld uw IP-adres, webbrowser en
                            besturingssysteem) zijn geen persoonsgegevens.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Medewerking aan fiscaal en strafrechtelijk
                            onderzoek</h3>
                        <p>

                            In voorkomende gevallen kan SideQuestShop op grond van een wettelijke verplichting worden
                            gehouden tot het
                            delen van uw gegevens in verband met fiscaal of strafrechtelijk onderzoek van overheidswege.
                            In
                            een dergelijk
                            geval zijn wij gedwongen uw gegevens te delen, maar wij zullen ons binnen de mogelijkheden
                            die
                            de wet ons biedt
                            daartegen verzetten.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Bewaartermijnen</h3>
                        <p>
                            Wij bewaren uw gegevens zolang u cliënt van ons bent. Dit betekent dat wij uw klantprofiel
                            bewaren totdat u
                            aangeeft dat u niet langer van onze diensten gebruik wenst te maken. Als u dit bij ons
                            aangeeft
                            zullen wij dit
                            tevens opvatten als een vergeetverzoek. Op grond van toepasselijke administratieve
                            verplichtingen dienen wij
                            facturen met uw (persoons)gegevens te bewaren, deze gegevens zullen wij dus voor zolang de
                            toepasselijke
                            termijn loopt bewaren. Medewerkers hebben echter geen toegang meer tot uw cliëntprofiel en
                            documenten die
                            wij naar aanleiding van uw opdracht hebben vervaardigd.
                        </p>

                        <h2 className={" prose-xl text-neutral-content"}>Uw rechten</h2>
                        <p>
                            Op grond van de geldende Nederlandse en Europese wetgeving heeft u als betrokkene bepaalde
                            rechten met
                            betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij leggen u
                            hieronder uit welke
                            rechten dit zijn en hoe u zich op deze rechten kunt beroepen. In beginsel sturen wij om
                            misbruik te voorkomen
                            afschriften en kopieën van uw gegevens enkel naar uw bij ons reeds bekende e-mailadres. In
                            het geval dat u de
                            gegevens op een ander e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen wij u
                            vragen zich te
                            legitimeren. Wij houden een administratie bij van afgehandelde verzoeken, in het geval van
                            een vergeetverzoek
                            administreren wij geanonimiseerde gegevens. Alle afschriften en kopieën van gegevens
                            ontvangt u in de
                            machineleesbare gegevensindeling die wij binnen onze systemen hanteren. U heeft te allen
                            tijde het recht om een
                            klacht in te dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij uw
                            persoonsgegevens op een
                            verkeerde manier gebruiken.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Inzagerecht</h3>
                        <p>
                            U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben
                            op uw persoon of
                            daartoe herleidbaar zijn, in te zien. U kunt een verzoek met die strekking doen aan onze
                            contactpersoon voor
                            privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt
                            ingewilligd
                            sturen wij u op het bij ons bekende e-mailadres een kopie van alle gegevens met een
                            overzicht van de verwerkers
                            die deze gegevens onder zich hebben, onder vermelding van de categorie waaronder wij deze
                            gegevens hebben
                            opgeslagen.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Rectificatierecht</h3>
                        <p>
                            U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben
                            op uw persoon of
                            daartoe herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die strekking doen aan
                            onze contactpersoon
                            voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek
                            wordt ingewilligd
                            sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens zijn
                            aangepast.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Recht op beperking van de verwerking</h3>
                        <p>
                            U heeft altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op
                            uw persoon of daartoe
                            herleidbaar zijn, te beperken. U kunt een verzoek met die strekking doen aan onze
                            contactpersoon voor
                            privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt
                            ingewilligd
                            sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens tot u de
                            beperking opheft niet
                            langer worden verwerkt.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Recht op overdraagbaarheid</h3>
                        <p>
                            U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben
                            op uw persoon of
                            daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. U kunt een verzoek met
                            die strekking doen aan
                            onze contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw
                            verzoek. Als uw
                            verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres afschriften of
                            kopieën van alle gegevens
                            over u die wij hebben verwerkt of in opdracht van ons door andere verwerkers of derden zijn
                            verwerkt. Naar alle
                            waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet langer
                            voortzetten, omdat de veilige
                            koppeling van databestanden dan niet langer kan worden gegarandeerd.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Recht van bezwaar en overige rechten</h3>
                        <p>
                            U heeft in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van uw
                            persoonsgegevens
                            door of in opdracht van SideQuestShop. Als u bezwaar maakt zullen wij onmiddellijk de
                            gegevensverwerking
                            staken in afwachting van de afhandeling van uw bezwaar. Is uw bezwaar gegrond dat zullen wij
                            afschriften en/of
                            kopieën van gegevens die wij (laten) verwerken aan u ter beschikking stellen en daarna de
                            verwerking blijvend
                            staken. U heeft bovendien het recht om niet aan geautomatiseerde individuele besluitvorming
                            of profiling te
                            worden onderworpen. Wij verwerken uw gegevens niet op zodanige wijze dat dit recht van
                            toepassing is. Bent u
                            van mening dat dit wel zo is, neem dan contact op met onze contactpersoon voor privacyzaken.
                        </p>

                        <h2 className={" prose-xl text-neutral-content"}>Cookies</h2>
                        <h3 className="prose-lg text-neutral-content">Google Analytics</h3>
                        <p>
                            Via onze website worden cookies geplaatst van het Amerikaanse bedrijf Google, als deel van
                            de “Analytics”-dienst.
                            Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe bezoekers de
                            website gebruiken.
                            Deze verwerker is mogelijk verplicht op grond van geldende wet- en regelgeving inzage te
                            geven in deze
                            gegevens. Wij verzamelen informatie over uw surfgedrag en delen deze gegevens met Google.
                            Google kan deze
                            informatie in samenhang met andere datasets interpreteren en op die manier uw bewegingen op
                            het internet
                            volgen. Google gebruikt deze informatie voor het aanbieden van onder andere gerichte
                            advertenties (Adwords) en
                            overige Google-diensten en producten.
                        </p>
                        <h3 className="prose-lg text-neutral-content">Cookies van derde partijen</h3>
                        <p>
                            In het geval dat softwareoplossingen van derde partijen gebruik maken van cookies is dit
                            vermeld in deze
                            privacyverklaring.
                        </p>
                        <h2 className={" prose-xl text-neutral-content"}>Wijzigingen in het privecybeleid</h2>
                        <p>
                            Wij behouden te allen tijde het recht ons privacybeleid te wijzigen. Op deze pagina vindt u
                            echter altijd de meest
                            recente versie. Als het nieuwe privacybeleid gevolgen heeft voor de wijze waarop wij reeds
                            verzamelde gegevens
                            met betrekking tot u verwerken, dan brengen wij u daarvan per e-mail op de hoogte.
                        </p>
                        <h2 className="prose-xl text-neutral-content">Contactgegevens</h2>
                        <h3 className="prose-lg text-neutral-content">SideQuestShop</h3>
                        <p>Geen bezoekadres</p>
                        <address>
                            Het Haam 4<br/>
                            6846KW Arnhem<br/>
                            Nederland
                        </address>
                        <div>

                        <a className={"btn btn-link px-0"} href={"mailto:info@sidequestshop.nl?subject=Vraag%20over%20het%20Privacybeleid"}>info@sidequestshop.nl</a>
                        </div>
                        <h3 className="prose-lg text-neutral-content">Contactpersoon voor privacyzaken</h3>
                        <p>Renzo Roso</p>

                        {/*<div className="card-actions justify-end">*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )

}
