import classNames from "classnames";
import {useMemo} from "react";

interface PictureProps {
    path: string;
    alt: string;
    isThumbnail?: boolean;
    full?: boolean;
    className?: string | string[];
}

export default function Picture({path, alt, isThumbnail = false, full = false, className}: PictureProps) {
    const srcPath = useMemo(() => {
        if (isThumbnail) return `${path}-thumbnail`.replace(/\/{2,}/g, '/')
        return path.replace(/\/{2,}/g, '/');
    }, [path, isThumbnail])

    return (
        <picture>
            <source srcSet={`${srcPath}.webp`}
                    className={classNames(full && 'w-full h-full', "select-none object-cover object-center group-hover:opacity-75", className)}
                    type="image/webp"/>
            <source srcSet={`${srcPath}.jpg`}
                    className={classNames(full && 'w-full h-full', "select-none object-cover object-center group-hover:opacity-75", className)}
                    type="image/jpeg"/>
            <source srcSet={`${srcPath}.png`}
                    className={classNames(full && 'w-full h-full', "select-none object-cover object-center group-hover:opacity-75", className)}
                    type="image/png"/>
            <img src={`${srcPath}.jpg`} alt={alt} loading={'lazy'}
                 className={classNames(full && 'w-full h-full', "select-none object-cover object-center group-hover:opacity-75", className)}/>
        </picture>
    )
}



export function ProductPicture({path, ...props}: PictureProps) {

    return (
        <Picture {...props} path={`/assets/images/products/${path}`}/>
    )
}

export function DisplaysPicture({path, ...props}: PictureProps) {

    return (
        <Picture {...props} path={`/assets/images/displays/${path}`}/>
    )
}