export enum RouteTitles {
    HOME = '',
    PRIVACY = 'Privacy',
    SHIPPINGRETURNS = 'Retouren',
    CONTACT = 'Contact',
    TERMS = 'Algemene voorwaarden',
    PRODUCTSLIST = 'Producten',
    PRODUCTDETAILS = '',

    CART = 'Winkelwagentje'
}