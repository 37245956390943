import React, {ReactNode} from 'react';


import './App.css';
import TopRouter from "./routers/TopRouter";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {StoreProvider} from "./context/StoreContext/StoreContext";

import './i18n';

const queryClient = new QueryClient()


function Providers({children}: { children: ReactNode }) {

    return (
        <QueryClientProvider client={queryClient}>
            <StoreProvider>
                {children}
            </StoreProvider>
        </QueryClientProvider>
    )
}


function App() {
    return (
        <>
            <Providers>
                <TopRouter/>
            </Providers>
        </>
    );
}

export default App;
