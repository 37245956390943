import Api from "./Api";


export class TranslationApi extends Api {

    constructor() {
        super();
    }

    async getTranslations() {
        const options = await this.genFetchOptions();
        const url = this.genUrl('/trans');

        const res = await fetch(url, options);
        return res.json();
    }

}

export default new TranslationApi();