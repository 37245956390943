import Api from "./Api";


export class ProductApi extends Api {

    constructor() {
        super();
    }

    async getProducts({limit = 12, offset = 0} : { limit?:number, offset?: number }) {
        const options = await this.genFetchOptions();
        const url = this.genUrl('/product', {limit, offset});

        const res = await fetch(url, options);
        return res.json();
    }

    async getProductDetail(variantUrl: string) {
        const options = await this.genFetchOptions()
        const url = this.genUrl(`/product/${variantUrl}`);

        const res = await fetch(url, options)
        return res.json();
    }

}

export default new ProductApi();