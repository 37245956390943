var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  en: () => en_exports,
  nl: () => nl_exports
});
module.exports = __toCommonJS(src_exports);

// src/en.ts
var en_exports = {};
__export(en_exports, {
  generics: () => generics,
  sentences: () => sentences,
  translation: () => translation,
  words: () => words
});
var import_ts_deepmerge = __toESM(require("ts-deepmerge"));
var words = {
  cart: {
    singular: "Cart"
  },
  products: {
    singular: "Product",
    plural: "Products"
  },
  information: "Information",
  review: "Review",
  option: {
    singular: "Option",
    plural: "Options"
  },
  order: {
    singular: "Order",
    plural: "Orders"
  },
  tax: {
    singular: "Tax",
    plural: "Taxes"
  },
  stock: "stock",
  estimate: "Estimate",
  subtotal: "Subtotal",
  amountDue: "Amount due",
  total: "Total",
  quantity: "Quantity",
  summary: {
    singular: "Summary",
    plural: "Summaries"
  },
  ready: "ready",
  ship: "Ship",
  shipping: "Shipping",
  visit: "visit",
  terms: "Terms and Conditions",
  privacyPolicy: "Privacy policy",
  warrantyShippingReturns: "Warranty, Shipping & Returns",
  contact: "Contact",
  email: "Email",
  address: "Address",
  account: {
    singular: "Account",
    plural: "Accounts"
  },
  add: "Add",
  remove: "Remove",
  not: "Not",
  invoice: {
    singular: "Invoice",
    plural: "Invoices"
  }
};
var generics = {
  shoppingCart: `Shopping ${words.cart.singular.toLowerCase()}`,
  emailAddress: `${words.email} ${words.address.toLowerCase()}`,
  checkout: "Checkout",
  orderSummary: `${words.order.singular} ${words.summary.singular.toLowerCase()}`,
  selectShippingMethod: `Select a ${words.shipping.toLowerCase()} method`,
  shippingCost: words.shipping,
  select: {
    placeholder: "Select on option"
  },
  payNow: "Pay now",
  invoiceNo: `${words.invoice.singular} number`
};
var sentences = {
  headerBar: {
    signIn: "Sign in",
    createAccount: "Create an account"
  },
  home: {
    christmas22: {
      hero: {
        title: "Happy holidays",
        subtitle: "Take a look at our holiday specials!",
        cta: words.visit
      }
    }
  },
  product: {
    detail: {
      productInformationLabel: `${words.products.singular} ${words.information.toLowerCase()}`,
      review: words.review,
      options: `${words.products.singular} ${words.option.plural.toLowerCase()}`
    }
  },
  cart: {
    itemsInCart: `${words.products.plural} in your ${generics.shoppingCart}`,
    add: `${words.add} to ${words.cart.singular.toLowerCase()}`,
    outOfStock: `Not in ${words.stock.toLowerCase()}`,
    checkout: generics.checkout
  },
  stock: {
    inStock: `In ${words.stock.toLowerCase()} and ${words.ready.toLowerCase()} to ${words.ship.toLowerCase()}`,
    outOfStock: `Out of ${words.stock.toLowerCase()}`
  },
  order: {
    shippingAddress: `${words.shipping} ${words.address.toLowerCase()}`,
    invoiceAddress: `${words.invoice.singular} ${words.address.toLowerCase()}`,
    subTotal: words.subtotal,
    selectShippingMethod: generics.selectShippingMethod,
    taxEstimate: `${words.tax.singular} estimate`,
    total: `${words.order.singular} ${words.total.toLowerCase()}`,
    orderSummary: generics.orderSummary
  },
  checkout: {
    title: generics.checkout,
    amountDue: "Amount due",
    paymentAndShippingDetails: `Payment and ${words.shipping} details`,
    contactInfo: `${words.contact} ${words.information.toLowerCase()}`,
    billingInformation: `Billing ${words.information.toLowerCase()}`,
    sameAsShipping: `Same as ${words.shipping.toLowerCase()} ${words.information.toLowerCase()}`,
    shippingMethods: `${words.shipping} methods`
  },
  orderSummary: {
    thanks: "Thanks for ordering",
    continue: "Continue shopping",
    awaitingPayment: "Waiting for payment..."
  },
  addressInfo: {
    firstname: "Firstname",
    middlename: "Middlename",
    lastname: "Lastname",
    addressLine1: "Addressline 1",
    addressLine2: "Addressline 2",
    postalCode: "Postal code",
    stateProvince: "State / Province",
    city: "City",
    country: "Country"
  },
  orderStatus: {
    arrangingPayment: "Payment pending",
    paid: "Payment successful",
    cancelled: `${words.order.singular} cancelled`,
    refunded: `${words.order.singular} refunded`,
    shipped: `${words.order.singular} verzonden`,
    delivered: `${words.order.singular} afgeleverd`
  }
};
var translation = (0, import_ts_deepmerge.default)(words, generics, sentences);

// src/nl.ts
var nl_exports = {};
__export(nl_exports, {
  generics: () => generics2,
  sentences: () => sentences2,
  translation: () => translation2,
  words: () => words2
});
var import_ts_deepmerge2 = __toESM(require("ts-deepmerge"));
var words2 = {
  cart: {
    singular: "Winkelwagentje"
  },
  products: {
    singular: "Product",
    plural: "Producten"
  },
  information: "Informatie",
  review: "Review",
  option: {
    singular: "Optie",
    plural: "Opties"
  },
  order: {
    singular: "Bestelling",
    plural: "Bestellingen"
  },
  tax: {
    singular: "BTW",
    plural: "BTW"
  },
  stock: "Voorraad",
  estimate: "Geschat",
  subtotal: "Subtotaal",
  amountDue: "Te betalen",
  total: "Totaal",
  quantity: "Aantal",
  summary: {
    singular: "Samenvatting",
    plural: "Samenvatting"
  },
  ready: "Klaar",
  ship: "Verzenden",
  shipping: "Verzend",
  visit: "Bekijken",
  terms: "Algemene voorwaaden",
  privacyPolicy: "Privacyverklaring",
  warrantyShippingReturns: "Garantie, Verzenden & Retourneren",
  contact: "Contact",
  email: "E-mail",
  address: "Adres",
  account: {
    singular: "Account",
    plural: "Accounts"
  },
  add: "Toevoegen",
  remove: "Verwijderen",
  not: "Niet",
  invoice: {
    singular: "Factuur",
    plural: "Facturen"
  }
};
var generics2 = {
  shoppingCart: words2.cart.singular,
  emailAddress: `${words2.email} ${words2.address.toLowerCase()}`,
  checkout: "Betalen",
  orderSummary: `${words2.order.singular} ${words2.summary.singular.toLowerCase()}`,
  selectShippingMethod: `Kies een ${words2.shipping.toLowerCase()} methode`,
  shippingCost: `${words2.shipping}kosten`,
  select: {
    placeholder: "Kies een optie"
  },
  payNow: "Nu betalen",
  invoiceNo: `${words2.invoice.singular}nummer`
};
var sentences2 = {
  headerBar: {
    signIn: "Inloggen",
    createAccount: `${words2.account.singular} aanmaken`
  },
  home: {
    christmas22: {
      hero: {
        title: "Fijne feestdagen",
        subtitle: "Helemaal klaar voor voor onze holiday specials!",
        cta: words2.visit
      }
    }
  },
  product: {
    detail: {
      productInformationLabel: `${words2.products.singular} ${words2.information.toLowerCase()}`,
      review: words2.review,
      options: `${words2.products.singular} ${words2.option.plural.toLowerCase()}`
    }
  },
  cart: {
    itemsInCart: `${words2.products.plural} in jouw ${generics2.shoppingCart}`,
    add: `${words2.add} aan ${generics2.shoppingCart.toLowerCase()}`,
    outOfStock: `Niet op ${words2.stock.toLowerCase()}`,
    checkout: generics2.checkout
  },
  stock: {
    inStock: `Op ${words2.stock.toLowerCase()} en ${words2.ready.toLowerCase()} om te ${words2.ship.toLowerCase()}.`,
    outOfStock: "Uitverkocht"
  },
  order: {
    shippingAddress: "Verzendgegevens",
    invoiceAddress: "Factuurgegevens",
    subTotal: words2.subtotal,
    selectShippingMethod: generics2.selectShippingMethod,
    taxEstimate: `${words2.tax.singular}`,
    total: `${words2.total}`,
    orderSummary: generics2.orderSummary
  },
  checkout: {
    title: generics2.checkout,
    amountDue: "Te betalen",
    paymentAndShippingDetails: `Betaal- en ${words2.shipping}gegevens`,
    contactInfo: `${words2.contact} ${words2.information.toLowerCase()}`,
    billingInformation: "Factuurgegevens",
    sameAsShipping: `Gelijk aan ${words2.shipping.toLowerCase()}gegevens`,
    shippingMethods: `${words2.shipping} opties`
  },
  orderSummary: {
    thanks: "Bedankt voor je bestelling",
    continue: "Verder winkelen",
    awaitingPayment: "Betaling nog niet ontvangen..."
  },
  addressInfo: {
    firstname: "Voornaam",
    middlename: "Tussenvoegsel",
    lastname: "Achternaam",
    addressLine1: "Straat + huisnummer",
    addressLine2: "Overige adres gegevens",
    postalCode: "Postcode",
    stateProvince: "Provincie",
    city: "Stad",
    country: "Land"
  },
  orderStatus: {
    arrangingPayment: "In afwachting van betaling",
    paid: "Betaald",
    cancelled: `${words2.order.singular} geannuleerd`,
    refunded: "Terugbetaald",
    shipped: `${words2.order.singular} verzonden`,
    delivered: `${words2.order.singular} afgeleverd`
  }
};
var translation2 = (0, import_ts_deepmerge2.default)(words2, generics2, sentences2);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  en,
  nl
});
