import {FaEnvelope, FaInstagram, FaPhone} from 'react-icons/fa'
import useConfig from "../hooks/useConfig";
import {RouteTitles} from "../enums/RouteTitles";
import {useTitle} from "react-use";

export default function Contact() {

    const config = useConfig()
    useTitle(`${config.siteName} | ${RouteTitles.CONTACT}`)

    return (
        <>
            <div className="relative">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <img
                            className="h-56 w-full object-cover lg:absolute lg:h-full"
                            src="/assets/images/SQ_Shield.png"
                            alt=""
                        />
                    </div>
                </div>
                <div
                    className="relative lg:mx-auto lg:grid lg:grid-cols-2 min-h-[32rem]">
                    <div className="lg:pr-8">
                        <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Contact</h2>
                            <dl className="mt-8 text-base text-gray-500 space-y-6">
                                <p className="text-lg text-gray-500">
                                    Send us a message through one of the addresses below, instagram or meet us at one of
                                    the events we're at.
                                    We’d love to hear from you!
                                </p>
                                <div>
                                    <dt>Return address, not for visiting</dt>
                                    <dd>
                                        <address>
                                            Het Haam 4<br/>
                                            6846KW Arnhem<br/>
                                            Nederland
                                        </address>
                                    </dd>
                                </div>
                                {/*<div className="mt-6">*/}
                                {/*    <dt className="sr-only">Phone number</dt>*/}
                                {/*    <dd className="flex">*/}
                                {/*        <FaPhone className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />*/}
                                {/*        <span className="ml-3">+1 (555) 123-4567</span>*/}
                                {/*    </dd>*/}
                                {/*</div>*/}
                                <div>
                                    <dt className="sr-only">Email</dt>
                                    <dd className="flex">
                                        <FaEnvelope className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                                        <a className={"ml-3"}
                                           href={"mailto:info@sidequestshop.nl?subject=Contact"}>info@sidequestshop.nl</a>

                                    </dd>
                                </div>
                                <div>
                                    <dt className="sr-only">Instagram</dt>
                                    <dd className="flex">
                                        <FaInstagram className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                                        <a className={"ml-3"}
                                           href={"https://www.instagram.com/SideQuestShop/"} target="_blank">@SideQuestShop</a>

                                    </dd>
                                </div>
                                <div>
                                    <dt className="sr-only">Phone</dt>
                                    <dd className="flex">
                                        <FaPhone className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true"/>
                                        <a className={"ml-3"}
                                           href={"tel:+31262060002"}>026-2060002</a>
                                    </dd>
                                </div>
                            </dl>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}