import {ProductTrack} from "../enums/Product";
import {Product, ProductVariant} from "@models/storefront";

export function stockTrackSwitch(trackStock: ProductTrack) {
    switch (trackStock) {
        case ProductTrack.TRACK:
            return true;
        case ProductTrack.NO_TRACK:
            return false;
        case ProductTrack.INHERIT:
            return 'inherit'
    }
}

export function trackProductVariantStock(product?: Product, variant?: ProductVariant) {
    if (!product || !variant) return;
    const trackVariant = stockTrackSwitch(variant.trackInventory);
    if (typeof trackVariant === 'boolean') return trackVariant;
    // if (trackVariant === 'inherit') {
    return stockTrackSwitch(product.trackInventory);
}

export function productVariantInStock(trackStock?: ReturnType<typeof trackProductVariantStock>, variant?: ProductVariant) {
    if (typeof trackStock === 'boolean') {
        if (!trackStock) return true;
        if (trackStock && variant!.stock > 0) return true;
    }

    return false;
}