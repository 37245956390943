import {Product, ProductVariant} from "@models/storefront";
import {useVariantInStock} from "../../hooks/useProduct";
import {FaCheck} from "react-icons/fa";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";

interface InStockLabelprops {
    product: Product;
    variant: ProductVariant;
}

export default function InStockLabel({product, variant}: InStockLabelprops) {
    const {inStock} = useVariantInStock(product, variant);
    const {t} = useTranslation();

    if (inStock) {
        return (
            <>
                <FaCheck className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true"/>
                <p className="ml-2 text-sm text-gray-500">{t('stock.inStock')}</p>
            </>
        )
    }


    return (
        <>
            <XMarkIcon className="h-5 w-5 font-bold flex-shrink-0 text-red-500"
                       aria-hidden="true"/>
            <p className="ml-2 text-sm text-gray-500">{t('stock.outOfStock')}</p>
        </>
    )
}