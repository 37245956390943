import {ReactNode, useContext} from "react";
import {StoreContext} from "../../context/StoreContext/StoreContext";
import classNames from "classnames";
import {FaBan} from 'react-icons/fa'

interface CookieRequiredButtonProps {
    children: ReactNode;
    onClick: () => void;
    className?: string;
}


export default function CookieRequiredButton({children, onClick, className}: CookieRequiredButtonProps) {
    const {cookieAccept, jwt} = useContext(StoreContext)

    if(!cookieAccept || !jwt) {
        return (
            <button onClick={() => {}} className={classNames(className, 'select-none bg-gray-600 hover:bg-gray-600')} disabled>
                <FaBan/>
                Please accept cookies first
            </button>
        )
    }

    return (
        <button onClick={onClick} className={classNames(className, 'select-none')}>
            {children}
        </button>
    )

}