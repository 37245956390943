import {useTranslations} from "../hooks/useTranslations";
import {useState} from "react";
import {ProductVariant} from "@models/storefront";
import {useCurrencyFormatter} from "../hooks/useCurrencyFormatter";
import {ProductPicture} from "../components/picture/Picture";
import {useProductList} from "../hooks/useProduct";
import useConfig from "../hooks/useConfig";
import {RouteTitles} from "../enums/RouteTitles";
import {useTitle} from "react-use";
import {useGA} from "../hooks/useGA";

export default function ProductsList() {
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(12);
    const {tApi, href} = useTranslations();
    const currencyFormatter = useCurrencyFormatter();
    useGA();

    const {isLoading, error, data,} = useProductList({offset, limit});

    const config = useConfig()
    useTitle(`${config.siteName} | ${RouteTitles.PRODUCTSLIST}`)

    return (
        <>
            {/*<h2 className="sr-only">Products</h2>*/}

            <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 ">
                {!isLoading && data && data.product.map((product, index: number) => {
                    const variant = product.variants[0];
                    const prices = [...new Set(product.variants.map((variant: ProductVariant) => variant.price!))];
                    const pricesBlock = prices.length === 1 ? currencyFormatter(prices[0]) : `${currencyFormatter(Math.min(...prices))} - ${currencyFormatter(Math.max(...prices))}`

                    return (
                        <a key={`${product.id}-${index}`} href={href(variant.url)} className="">
                            <div
                                className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                                <ProductPicture full path={`${variant.images[0]}-sm`} alt={tApi(product.name)}/>
                            </div>
                            <h3 className="mt-4 text-sm text-gray-700">{tApi(product.name)}</h3>
                            <p className="mt-1 text-lg font-medium text-gray-900">{pricesBlock}</p>
                        </a>
                    )
                })}
            </div>
        </>
    )


}