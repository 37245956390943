interface EnvConfig {
    apiUrl: string;
    ga: string;
    siteName: string;
    env: string;
}

interface ReactEnvConfig {
    REACT_APP_API_URL?: string;
    REACT_APP_GA?: string;
    REACT_APP_SITE_TITLE?: string;
    REACT_APP_ENV?: string;
}

const config: EnvConfig = {
    apiUrl: 'http://localhost:8080',
    ga: 'G-SETHDKL913',
    siteName: 'SideQuest',
    env: 'development',
    // cdnUrl: 'https://cdn.painapp.3tdev.cloud',
}

function getEnvVarsFromObject(config: EnvConfig, envObject: ReactEnvConfig) {
    if (envObject.REACT_APP_API_URL) config.apiUrl = envObject.REACT_APP_API_URL;
    if (envObject.REACT_APP_SITE_TITLE) config.siteName = envObject.REACT_APP_SITE_TITLE;
    if (envObject.REACT_APP_GA) config.ga = envObject.REACT_APP_GA;
    if (envObject.REACT_APP_ENV) config.env = envObject.REACT_APP_ENV;
    // if (envObject.REACT_APP_CDN_URL) config.cdnUrl = envObject.REACT_APP_CDN_URL;
}

// if (!!process && process.env) {
//     getEnvVarsFromObject(config, process.env as ReactEnvConfig);
// }

if (window) {
    getEnvVarsFromObject(config, window as ReactEnvConfig);
}

export default config;